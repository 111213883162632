import {firebaseApp} from '../../firebaseConfig';
import db from '../../firebaseConfig';
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  updateProfile,
  sendPasswordResetEmail,
} from 'firebase/auth';
import 'firebase/auth';
import {
  collection,
  addDoc,
  Timestamp,
  query,
  getDocs,
  where,
  updateDoc,
} from 'firebase/firestore';

const createUserProfile = async (uid, email, name, mobile, address) => {
  const now = new Date();
  const docRef = await addDoc(
      collection(db, 'users'),
      {
        uid,
        name,
        mobile,
        email,
        createdAt: Timestamp.fromDate(now),
        address,
        type: 'customer',
      },
  );
  return docRef;
};

const getUserProfile = async (uid) => {
  try {
    const q = query(collection(db, 'users'), where('uid', '==', uid));
    const querySnapshot = await getDocs(q);
    if (!querySnapshot.empty) {
      const documentSnapshot = querySnapshot.docs[0];
      const documentData = documentSnapshot.data();
      return documentData;
    }
    return {};
  } catch (error) {
    console.log(error);
  }
};

/**
 * get user profile.
 *
 * @param {string} uid - The string uid for profile.
 * @return {Promise} A promise for registerUser.
 */
export function getProfile(uid) {
  return new Promise((resolve) =>
    resolve(getUserProfile(uid)),
  );
}

const register = async (
    email,
    password,
    name,
    mobile,
    flatNo,
    selectedLocation,
) => {
  const auth = getAuth(firebaseApp);
  try {
    const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password,
    );
    const user = userCredential.user;
    const {
      id,
      addressLine1,
      addressLine2,
      apartmentName,
      city,
      state,
      pinCode,
    } = selectedLocation;
    createUserProfile(
        user.uid,
        email,
        name,
        mobile,
        [
          {
            addressLine1,
            addressLine2,
            apartmentName,
            city,
            flatNo,
            locationId: id,
            name,
            phoneNumber: mobile,
            pinCode,
            state,
            uid: user.uid,
          },
        ],
    );

    updateProfile(auth.currentUser, {
      displayName: name, phoneNumber: mobile,
    }).then(() => {
      // Profile updated!
      // ...
    }).catch((error) => {
      console.log(error);
    });
    return {...user, email, name, mobile};
  } catch (error) {
    if (error.code === 'auth/email-already-in-use') {
      return 'EMAIL_EXISTS';
    }
    throw new Error('register failed.');
  }
};

/**
 * register user.
 *
 * @param {string} email - The string email for register.
 * @param {string} password - The string password for register.
 * @param {string} name - The string name for register.
 * @param {string} mobile - The string mobile for register.
 * @param {string} flatNo - The string mobile for register.
 * @param {string} selectedLocation - The string mobile for register.
 * @return {Promise} A promise for registerUser.
 */
export function registerUser(
    email,
    password,
    name,
    mobile,
    flatNo,
    selectedLocation,
) {
  return new Promise((resolve) =>
    resolve(register(email, password, name, mobile, flatNo, selectedLocation)),
  );
}

const login = async (email, password) => {
  const auth = getAuth(firebaseApp);
  try {
    const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password,
    );
    const user = userCredential.user.toJSON();
    // get user claims
    let role = '';
    await auth.currentUser.getIdTokenResult()
        .then((idTokenResult) => {
          role = idTokenResult.claims.role;
        })
        .catch((error) => {
          // error getting claim
        });
    const profile = await getUserProfile(user.uid);
    return {...user, role, ...profile};
  } catch (error) {
    if (error.code === 'auth/user-not-found') {
      return 'EMAIL_NOT_FOUND';
    }
    if (error.code === 'auth/too-many-requests') {
      return 'TOO_MANY_REQUESTS';
    }
    throw new Error(
        'Login failed.Please check your email and password and try again.',
    );
  }
};

/**
 * login user.
 *
 * @param {string} email - The string email for login.
 * @param {string} password - The string password for login.
 * @return {Promise} A promise for loginUser.
 */
export function loginUser(email, password) {
  return new Promise((resolve) => {
    resolve(login(email, password));
  });
}

/**
 * logout user.
 *
 * @return {Promise} A promise that logout user.
 */
export function logoutUser() {
  return new Promise((resolve) => {
    resolve(logout());
  });
}

const logout = async () => {
  const auth = getAuth(firebaseApp);
  try {
    return await auth.signOut();
  } catch (error) {
    throw new Error('Logout failed');
  }
};

/**
 * @param {string} email - The string email for reset password.
 * reset user password.
 *
 * @return {Promise} A promise that reset password for user.
 */
export function resetUserPassword(email) {
  return new Promise((resolve) => {
    resolve(resetPassword(email));
  });
}

const resetPassword = async (email) => {
  try {
    const auth = getAuth(firebaseApp);
    let status = 'test';
    await sendPasswordResetEmail(auth, email).then((res) => {
      status = 'RESET_EMAIL_SEND';
    }).catch((error) => {
      if (error.code === 'auth/user-not-found') {
        status = 'RESET_EMAIL_USER_NOT_FOUND';
      }
      status = 'RESET_EMAIL_FAILED';
    });
    return status;
  } catch (error) {
    throw new Error('reset failed');
  }
};

const saveCustomerAddress = async (address) => {
  const q = query(collection(db, 'users'), where('uid', '==', address.uid));
  const customerAddress = {address: [address]};
  try {
    const querySnapshot = await getDocs(q);
    if (!querySnapshot.empty) {
      querySnapshot.forEach(async (doc) => {
        await updateDoc(doc.ref, customerAddress);
      });
      return customerAddress;
    } else {
      createUserProfile(
          address.uid,
          '',
          address.name,
          address.phoneNumber,
          address,
      );
      return customerAddress;
    }
  } catch (error) {
    console.error('Error querying documents:', error);
  }
};

/**
 * Save Address
 *
 * @param {object} address - The address object to be saved.
 * @return {Promise} A promise to save the address.
 */
export function saveAddress(address) {
  return new Promise((resolve) =>
    resolve(saveCustomerAddress(address)),
  );
}
