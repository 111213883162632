import React from 'react';
import './Footer.css';
import PropTypes from 'prop-types';
import FooterContent from './FooterContent';
import FooterMenu from './FooterMenu';

/**
 * Component that displays the Footer page
 * @param {Object} props - The properties passed to the component.
 * @param {String} props.tab - The tab prop.
 * @return {JSX.Element} Footer component JSX
 */
export default function Footer({tab}) {
  return (
    <>
      <FooterContent/>
      <FooterMenu/>
    </>
  );
}

// PropTypes validation
Footer.propTypes = {
  tab: PropTypes.string.isRequired,
};
