import React, {useState} from 'react';
import {FiShoppingCart} from 'react-icons/fi';
import Select from 'react-select';
import PropTypes from 'prop-types';
import {useSelector, useDispatch} from 'react-redux';
import {
  addToCart,
  cart,
} from '../../../features/products/productSlice';
import Buttons from '../../Buttons/Buttons';
import './Card.css';
import {Tooltip, Whisper, Button} from 'rsuite';
import HelpOutlineIcon from '@rsuite/icons/HelpOutline';

/**
 * Component that displays the Card page
 *
 * @param {Object} props - The props object containing product information
 * @return {JSX.Element} Card component JSX
 */
export default function Card(props) {
  const dispatch = useDispatch();
  const basket = useSelector(cart);
  const OptionsForProduct = props.product_options
      .filter((item) => !(props.isMultiVariantProduct && item.isPrimary))
      .map((item) => ({
        ...item,
        value: item.id,
        label: item.optionTitle,
      }));

  const primaryOption = props.isMultiVariantProduct ?
    props.product_options.find((item) => item.isPrimary === true) : null;

  const tooltip = (
    <Tooltip>
      Option prices are calculated based on the higher weight,
       but your final bill will be determined by the actual weight.
    </Tooltip>
  );

  const [selectedOption, setSelectedOption] = useState(OptionsForProduct[0]);
  const handleChange = (selectedOption) => {
    setSelectedOption(selectedOption);
  };

  const handleAddToCart = (selectedOption) => {
    const selectedProduct = props;
    dispatch(addToCart({
      optionId: selectedOption.id,
      productId: props.id,
      sellPrice: selectedOption.sellPrice,
      originalPrice: selectedOption.originalPrice,
      image: selectedProduct.image,
      optionTitle: selectedOption.optionTitle,
      category: selectedProduct.category,
      count: selectedOption.count,
      title: selectedProduct.title,
      odooId: selectedProduct.odoo_id,
      isMultiVariant: selectedProduct.isMultiVariantProduct,
      weightRatio: selectedOption.weight_ratio,
    }));
  };

  const checkBasket = selectedOption ?
    basket.some(
        (productOption) => productOption.optionId === selectedOption.id,
    ) :
    false;

  const qtyAvailable = props.qty_available ? props.qty_available : 0;
  const image = `/images/products/${props.image === '' ?
    'default_prod.jpg' :
    props.image}`;
  return qtyAvailable > 0 ? (
    <div key={props.id} className="box">
      <div className="box-content">
        <div className="product-img-container">
          <img className="product_img" src={image} alt="product" />
        </div>
        <div className="product-content">
          <div className="product-content-texts">
            <div className="title">
              {props.title}
              {props.isMultiVariantProduct && primaryOption && (
                <div className="multi_variant_title">
                  <Whisper
                    placement="top"
                    controlId="control-id-hover"
                    trigger="hover"
                    speaker={tooltip}
                  >
                    <Button>
                      {primaryOption.optionTitle} {primaryOption.sellPrice}/ kg
                    </Button>
                  </Whisper>
                  <Whisper
                    placement="top"
                    controlId="control-id-click"
                    trigger="click"
                    speaker={tooltip}
                  >
                    <HelpOutlineIcon />
                  </Whisper>
                </div>
              )}
            </div>
            <div className="price">
              <span>Rs {selectedOption ?
                selectedOption.sellPrice.toLocaleString() :
                ''}
              </span>
            </div>
          </div>
          <div>
            {OptionsForProduct.length > 1 ? (
              <Select
                value={selectedOption}
                onChange={handleChange}
                options={OptionsForProduct}
                className='option_select'
              />
            ) : (
              OptionsForProduct.length > 0 ?
                (<span>{ OptionsForProduct[0].optionTitle }</span>) :
                null
            )}
          </div>
        </div>
      </div>
      {checkBasket ? (
        <Buttons optionId={selectedOption.id}/>
      ) : (
        <button
          onClick={() => {
            handleAddToCart(selectedOption);
          }}
          className="products_button buy_button"
        >
          Buy
          <FiShoppingCart className="buy_icon" />
        </button>
      )}
    </div>
  ) : null;
}

Card.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  optionId: PropTypes.string,
  qty_available: PropTypes.number,
  product_options: PropTypes.array,
  isMultiVariantProduct: PropTypes.bool.isRequired,
};
