export const categories = [
  {category: 'ALL'},
  {category: 'VEGETABLE'},
  {category: 'FRUIT'},
  {category: 'LEAFY_VEGETABLES'},
  {category: 'FLOWERS'},
  {category: 'STAPLES'},
  {category: 'HONEY'},
  {category: 'OIL'},
  {category: 'MILK_PRODUCTS'},
  {category: 'DRY_FRUITS'},
  {category: 'SPICES'},
];
export const CategoryTranslations = {
  ALL: 'Home',
  VEGETABLE: 'Veggies',
  FRUIT: 'Fruits',
  LEAFY_VEGETABLES: 'Leafy',
  FLOWERS: 'Flowers',
  STAPLES: 'Staples',
  MILK_PRODUCTS: 'Milk Products',
  DRY_FRUITS: 'Dry fruits',
  SPICES: 'Spices',
  HONEY: 'Honey',
  OIL: 'Oil',
};

export const CategoryImages = {
  ALL: 'fruits.jpg',
  VEGETABLE: 'veggies.jpg',
  FRUIT: 'fruits.jpg',
  LEAFY_VEGETABLES: 'leafs.jpg',
  FLOWERS: 'flowers.jpg',
  STAPLES: 'staples.jpg',
  MILK_PRODUCTS: 'milk.jpg',
  DRY_FRUITS: 'dryfruits.jpg',
  SPICES: 'spices.jpg',
  HONEY: 'honey.jpg',
  OIL: 'oil.jpg',
};
