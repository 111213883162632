import db from '../../firebaseConfig';
import {
  collection,
  getDocs,
  query,
  where,
  orderBy,
  addDoc,
  Timestamp,
  doc,
  updateDoc,
} from 'firebase/firestore';


const getCacheKeys = async () => {
  const q = query(collection(db, 'cache_keys'));
  const querySnapshot = await getDocs(q);
  if (!querySnapshot.empty) {
    const documentSnapshot = querySnapshot.docs[0];
    const documentData = documentSnapshot.data();
    return documentData;
  }
  return {};
};

/**
 * fetch cache keys.
 *
 * @return {Promise} A promise that resolves with cache keys.
 */
export function fetchCacheKeys() {
  return new Promise((resolve) => {
    resolve(getCacheKeys());
  });
}

const getProducts = async (category) => {
  const res = [];
  const q = category ?
    query(collection(db, 'products'),
        where('category', '==', category),
        where('qty_available', '>', 0),
        orderBy('qty_available'),
        orderBy('title')) :
    query(collection(db, 'products'),
        where('qty_available', '>', 0),
        orderBy('qty_available'),
        orderBy('title'));
  try {
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      const data = doc.data();
      res.push({id: doc.id, ...data});
    });
    return {data: res};
  } catch (e) {
    return {data: res};
  }
};

/**
 * fetch all products.
 *
 * @param {string} category - The string category to fetch products.
 * @return {Promise} A promise that resolves with products.
 */
export function fetchProducts(category) {
  const productsPromise = getProducts(category);
  return Promise.all([productsPromise])
      .then(([products]) => {
        return {data: {products}};
      });
}

const saveCart = async (cart, user, addresses) => {
  try {
    const now = new Date();
    const docRef = await addDoc(
        collection(db, 'cart'),
        {
          cart,
          createdAt: Timestamp.fromDate(now),
          uid: user,
          addresses: addresses,
        },
    );
    const id = docRef.id;
    return {

      status: 'success',
      cartId: id,
    };
  } catch (error) {
    return {
      status: 'failed',
    };
  }
};

/**
 * create / update cart in firebase
 *
 * @param {object} cart - The object cart.
 * @param {string} user - The string uid.
 * @param {object} addresses - The object addresses.
 * @return {Promise} A promise that resolves with products.
 */
export function createOrUpdateCart(cart, user, addresses) {
  return saveCart(cart, user, addresses);
}

const setSuccessCart = async (cartId) => {
  try {
    const docRef = doc(db, 'cart', cartId);
    await updateDoc(docRef, {status: 'SUCCESS'});
    return {
      status: 'success',
    };
  } catch (error) {
    return {
      status: 'success',
    };
  }
};

/**
 * Mark cart as successful
 *
 * @param {string} cartId - The string category to fetch products.
 * @return {Promise} A promise that resolves with products.
 */
export function markCartSuccess(cartId) {
  return setSuccessCart(cartId);
};
