import React from 'react';
import './Footer.css';

/**
 * Component that displays the Footer page
 * @param {Object} props - The properties passed to the component.
 * @return {JSX.Element} Footer component JSX
 */
export default function Footer() {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="logo-content">
          Copyright © 2023-2024 Green Yogi Naturals Pvt Ltd.
        </div>
        <section className="collapse-content">
          <h5>Shop</h5>
          <a href='/products/VEGETABLE'>Vegetables</a>
          <a href='/products/FRUIT'>Fruits</a>
          <a href='/products/LEAFY_VEGETABLES'>Leafy Vegetables</a>
          <a href='/products/FLOWERS'>Flowers</a>
          <a href='/products/STAPLES'>Staples</a>
          <a href='/products/HONEY'>Honey</a>
          <a href='/products/MILK_PRODUCTS'>Milk Products</a>
          <a href='/products/DRY_FRUITS'>Dry Fruits</a>
          <a href='/products/SPICES'>Spices</a>
        </section>

        <section className="collapse-content">
          <h5>Customer Service</h5>
          <span>contact@greenyogi.in</span>
        </section>
        <section className="collapse-content">
          <h5>Credits</h5>
          <a href='/creatives'>Creatives</a>
        </section>
      </div>
    </footer>
  );
}
