import React, {useEffect} from 'react';
import './Header.css';
import {AiOutlineShopping} from 'react-icons/ai';
import {FaLocationArrow} from 'react-icons/fa';

import {TbTruckDelivery} from 'react-icons/tb';
import {Link, useLocation} from 'react-router-dom';
import {useSelector, useDispatch} from 'react-redux';
import {cart, showHalfCart} from '../../features/products/productSlice';
import {
  initialLocation,
  allLocations,
  getLocations,
} from '../../features/locations/locationSlice';
import {
  selectedAddress,
  customerAddresses,
} from '../../features/user/userSlice';
import UserDropdown from '../DropdownMenu/DropDownMenu';
import SearchBar from '../SearchBar/SearchBar';
import CartDrawer from '../Basket/CartDrawer/CartDrawer';
import {getDeliverySlot} from '../../util/delivery';

/**
 * Component that displays the Header Page page
 *
 * @return {JSX.Element} Header Page component JSX
 */
function Header() {
  const basket = useSelector(cart);
  const dispatch = useDispatch();
  const selectedLocation = useSelector(initialLocation);
  const selectedCustomerAddress = useSelector(selectedAddress);
  // Get location for hide & show SearchBar Component
  const location = useLocation();
  const {pathname} = location;

  useEffect(() => {
    dispatch(getLocations());
  }, []);

  const handleCartClick = () => {
    dispatch(showHalfCart(true));
  };

  const customerLocation = selectedCustomerAddress.apartmentName ?
    selectedCustomerAddress.apartmentName : selectedLocation.apartmentName ?
    selectedLocation.apartmentName : '';
  const addresses = useSelector(customerAddresses);
  const locationsList = useSelector(allLocations);
  const deliverySlotTimes =
    getDeliverySlot(
      addresses.length > 0 ? addresses : [
        {...selectedLocation, locationId: selectedLocation.id},
      ],
      locationsList,
    );
  /* const deliveryDate =
    deliverySlotTimes ?
      deliverySlotTimes.deliveryDateTime.toLocaleDateString('en-GB') :
      ''; */
  // eslint-disable-next-line max-len
  const cutOffTime = deliverySlotTimes ? deliverySlotTimes.targetTime.toLocaleTimeString('en-IN', {hour: '2-digit', minute: '2-digit'}) : '';
  // eslint-disable-next-line max-len
  // const nextDeliverySlot = deliverySlotTimes ? `${deliveryDate} ${deliverySlotTimes.from} to ${deliverySlotTimes.to}` : '';
  const nextDeliverySlot = deliverySlotTimes ? deliverySlotTimes.to : '';
  return (
    <>
      <header className="header">
        <nav className="nav">
          <Link to={'/'} className="logo">
            <img src="/images/green_yogi_logo_80.png" alt="" />
          </Link>
          <div className="search_header">
            {pathname === '/' && <SearchBar />}
          </div>
          <div className="icon_Sopping_box">
            <div className="shoppe_icon_box" onClick={handleCartClick}>
              <AiOutlineShopping className="shop_icon" />
              <span className="nav_text">Cart</span>
              {basket.length > 0 && (
                <span className="badge_shope">{basket.length}</span>
              )}
            </div>
            <UserDropdown className="mark_icon" />
          </div>
        </nav>
      </header>
      <CartDrawer/>
      <div className='selected_location'>
        <div>
          <FaLocationArrow/>
          <span>{customerLocation}</span>
        </div>
      </div>
      <div className='deliveryTime'>
        <div>
          <TbTruckDelivery/>
          <span>Order by {cutOffTime}
          &nbsp;and get before {nextDeliverySlot}</span>
        </div>
      </div>
    </>
  );
}

export default Header;
