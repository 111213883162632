import db from '../../firebaseConfig';
import {
  collection,
  addDoc,
  Timestamp,
} from 'firebase/firestore';

const saveEvent = async (event) => {
  try {
    const now = new Date();
    const docRef = await addDoc(
        collection(db, 'events'),
        {...event, createdAt: Timestamp.fromDate(now)},
    );
    const id = docRef.id;
    return {
      status: 'success',
      id,
    };
  } catch (error) {
    return {
      status: 'failed',
    };
  }
};

/**
 * Place event.
 *
 * @param {string} event - The Object event to be saved.
 * @return {Promise} A promise that saves the event.
 */
export function placeEvent(event) {
  return new Promise((resolve) =>
    resolve(saveEvent(event)),
  );
}
