import React, {useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {ImSpinner5} from 'react-icons/im';
import {
  allOrders,
  getAllOrders,
  orderFetchStatus,
} from '../../features/order/orderSlice';
import {useAuth} from '../../features/user/userSlice';
import './Orders.css';
import Order from './Order';
import Footer from '../Footer/Footer';

/**
 * Component that displays the orders page
 *
 * @return {JSX.Element} Orders component JSX
 */
export default function Orders() {
  const dispatch = useDispatch();
  const allCustomerOrders = useSelector(allOrders);
  const fetchStatus = useSelector(orderFetchStatus);
  const user = useAuth();
  const userId = user ? user.uid : null;
  const navigate = useNavigate();
  useEffect(() => {
    if (userId) {
      dispatch(getAllOrders(userId));
    } else {
      navigate('/login?redirect=/orders');
    }
  }, []);

  return (
    <>
      {userId ? (
        <div className="order-container">
          {allCustomerOrders.length > 0 ? (
              allCustomerOrders
                  .map((order) =>
                    <Order key={order.id} {...order} />)
            ) : (
            <div>
              <span className="spinner-text">
                {fetchStatus === 'fulfilled' ?
                  'No Orders Found.' :
                  'Please wait while we fetch your orders.'
                }
              </span>
              <div className="spinner-container">
                {fetchStatus === 'fulfilled' ?
                  null :
                  <ImSpinner5 className="spinner" />
                }
              </div>
            </div>
          )}
        </div>
      ) : (
        <div className="order-container">
          Please login to access orders.
        </div>
      )};
      <Footer tab={'orders'}/>
    </>
  );
}
