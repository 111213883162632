import React from 'react';
import './SearchBar.css';
import {FiSearch} from 'react-icons/fi';
import {useSelector, useDispatch} from 'react-redux';
import {
  setSearchKey,
  searchKey,
} from '../../features/products/productSlice';

/**
 * Component that displays the SearchBar page
 *
 * @return {JSX.Element} SearchBar component JSX
 */
export default function SearchBar() {
  const dispatch = useDispatch();
  const searchInput = useSelector(searchKey);

  const searchKeywordHandler = (e) => {
    dispatch(setSearchKey(e.target.value));
  };

  return (
    <div className="searchBar_box">
      <input
        onChange={(e) => searchKeywordHandler(e)}
        type="text"
        placeholder="Search"
        value={searchInput}
      />
      <span>
        <FiSearch />
      </span>
    </div>
  );
}
