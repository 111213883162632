import React, {useState, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {ImSpinner5} from 'react-icons/im';
import {
  registration,
  login,
  logout,
  // loggedInUser,
  registrationError,
  clearError,
  loginError,
  uid,
  saveAddressStatus,
  // selectedAddress,
  resetPassword,
  passwordResetError,
} from '../../features/user/userSlice';
// import NewAddress from '../Address/NewAddress';
import {showHalfCart} from '../../features/products/productSlice';
import {
  // setInitialAddress,
  initialLocation,
} from '../../features/locations/locationSlice';

/**
 * Component that displays the login page
 *
 * @return {JSX.Element} Login component JSX
 */
export default function LoginEmail() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [password2, setPassword2] = useState('');
  const [name, setName] = useState('');
  const [flatNo, setFlatNo] = useState('');
  const [mobile, setMobile] = useState('');
  const [showRegister, setShowRegister] = useState(false);
  // const [showLocation, setShowLocation] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [showForgotPassword, setShowForgotPassword] = useState(false);

  const dispatch = useDispatch();

  const user = useSelector(uid);
  const errorRegistration = useSelector(registrationError);
  const errorLogin = useSelector(loginError);
  const errorResetPassword= useSelector(passwordResetError);
  const addressStatus = useSelector(saveAddressStatus);
  // const userAddress = useSelector(selectedAddress);
  const selectedLocation = useSelector(initialLocation);
  const searchParams = new URLSearchParams(location.search);
  const redirect = searchParams.get('redirect');
  // eslint-disable-next-line max-len
  const locationTxt = `${selectedLocation.apartmentName ? selectedLocation.apartmentName : ''}`;
  const navigate = useNavigate();
  useEffect(() => {
    if (user) {
      setShowLoader(false);
      if (showRegister) {
        // setShowLocation(true);
        // if (addressStatus === 'fulfilled') {
        dispatch(showHalfCart(false));
        // dispatch(setInitialAddress(userAddress));
        navigate(redirect ? redirect : '/checkout');
        // }
      } else {
        dispatch(showHalfCart(false));
        navigate(redirect ? redirect : '/checkout');
      }
    }
  }, [user, addressStatus, navigate]);

  const handleLogin = () => {
    dispatch(login({email, password}));
    setShowLoader(true);
    dispatch(clearError());
  };

  const handleRegister = () => {
    dispatch(registration({
      email,
      password,
      name,
      mobile,
      flatNo,
      selectedLocation,
    }));
    setShowLoader(true);
  };

  const handleLogout = () => {
    dispatch(logout());
    setShowLoader(true);
  };

  const clearValues = () => {
    setEmail('');
    setPassword('');
    setPassword2('');
    setName('');
    setMobile('');
    setShowLoader('');
    dispatch(clearError());
  };

  const handleShowRegister = () => {
    setShowRegister(true);
    clearValues();
  };

  const handleShowLogin = () => {
    setShowRegister(false);
    setShowForgotPassword(false);
    clearValues();
  };

  const handleForgotPassword = () => {
    clearValues();
    setShowForgotPassword(true);
  };

  const handleResetPassword = () => {
    setShowLoader(true);
    dispatch(clearError());
    dispatch(resetPassword(email));
  };

  const disableLogin = () => {
    const isValidEmail = String(email)
        .toLowerCase()
        .match(
            // eslint-disable-next-line max-len
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        );
    const isValidPassword = password.length > 7;
    return !isValidEmail || !isValidPassword;
  };

  const disableForgotPassword = () => {
    const isValidEmail = String(email)
        .toLowerCase()
        .match(
            // eslint-disable-next-line max-len
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        );
    return !isValidEmail;
  };

  const isValidEmail = () => {
    return String(email)
        .toLowerCase()
        .match(
            // eslint-disable-next-line max-len
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        );
  };

  const isValidPassword = () => {
    return password.length > 7;
  };

  const isValidName = () => {
    return String(name).toLocaleLowerCase().match(
        /^[a-z][a-z\s]*$/,
    );
  };

  const isValidMobile = () => {
    return String(mobile).toLocaleLowerCase().match(
        /^[0-9]+$/,
    ) && mobile.length === 10;
  };

  const isValidVerifyPassword = () => {
    return password2 === password;
  };

  const disableRegistration = () => {
    // eslint-disable-next-line max-len
    return !isValidEmail() || !isValidPassword() || !isValidName() || !isValidMobile() || !isValidVerifyPassword();
  };

  const getErrorMessage = () => {
    if (!isValidName() && name !== '') {
      return 'Please enter a valid name.';
    }
    if (!isValidMobile() && mobile !== '') {
      return 'Please enter a valid mobile number.';
    }
    if (!isValidEmail() && email !== '') {
      return 'Please enter a valid email address.';
    }
    if (!isValidPassword() && password !== '') {
      return 'Please enter a valid password.';
    }
    if (!isValidVerifyPassword() && password2 !== '') {
      return 'Confirm password not matching, Please correct your password.';
    }
  };

  let loginErrorMsg = '';
  switch (errorLogin) {
    case 'EMAIL_NOT_FOUND':
      loginErrorMsg = 'Email Id not found';
      break;
    case 'TOO_MANY_REQUESTS':
      loginErrorMsg = 'Too many failed requests, please try again later';
      break;
    case 'LOGIN_ERROR':
      loginErrorMsg = 'Login error, please try again';
  }
  let resetErrorMsg = '';
  switch (errorResetPassword) {
    case 'RESET_EMAIL_USER_NOT_FOUND':
      resetErrorMsg = 'Email not found. Please try with a valid email.';
      break;
    case 'RESET_EMAIL_FAILED':
      resetErrorMsg = 'Email reset failed. Please try again later.';
      break;
    case 'RESET_EMAIL_SEND':
      // eslint-disable-next-line max-len
      resetErrorMsg = 'A password reset link has been sent to your email. Please follow the link to reset your password.';
      break;
  }

  return (
    <div>
      {!user ?
        <>
          {!showRegister ?
            showForgotPassword ?
              <div className='login-email'>
                <h2>Forgot Password</h2>
                <form>
                  <div className='error-message'>
                      Back to <a onClick={handleShowLogin}>login</a>
                  </div>
                  <div className='error-message'>
                    {resetErrorMsg ? resetErrorMsg : null}
                  </div>
                  <input
                    type="text"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <button
                    type="button"
                    onClick={handleResetPassword}
                    disabled={disableForgotPassword()}
                  >
                    {showLoader && !resetErrorMsg ?
                      <ImSpinner5 className="spinner" /> : 'Reset Password'}
                  </button>
                </form>
              </div> :
            <div className='login-email'>
              {errorLogin ? loginErrorMsg : null}
              <h2>Login</h2>
              <form>
                <input
                  type="text"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <input
                  type="password"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <button
                  type="button"
                  onClick={handleLogin}
                  disabled={disableLogin()}
                >
                  {showLoader && !errorLogin ?
                    <ImSpinner5 className="spinner" /> : 'Login'}
                </button>
                <a
                  className="btn-forgot-password"
                  onClick={handleForgotPassword}
                >
                  Forgot Password
                </a>
                <button type="button" onClick={handleShowRegister}>
                  New customer? Start here.
                </button>
              </form>
            </div> :
            <div className='register-email'>
              {errorRegistration === 'EMAIL_EXISTS' ?
                <div className='error-message'>
                  Email id already exist, please try to
                  <a onClick={handleShowLogin}>login</a>
                </div> :
                <div className='error-message'>
                    Back to <a onClick={handleShowLogin}>login</a>
                </div>
              }
              <input
                type="text"
                placeholder="Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                pattern="[A-Za-z ]+"
                required
              />
              <span className="instruction">
                Please enter the full name of the customer
                (Only alphabets are allowed).
              </span>
              <input
                type="text"
                placeholder="Flat Number"
                value={flatNo}
                onChange={(e) => setFlatNo(e.target.value)}
                pattern="[A-Za-z0-9 ]+"
                required
              />
              <span className="instruction">
                Please enter the flat number at {locationTxt}.
              </span>
              <input
                type="text"
                placeholder="Mobile Number"
                value={mobile}
                onChange={(e) => setMobile(e.target.value)}
                pattern="[0-9]{10}"
                required
              />
              <span className="instruction">
                Please enter the mobile number (10 digits only).
              </span>
              <input
                type="text"
                placeholder="Email"
                value={email}
                required
                onChange={(e) => setEmail(e.target.value)}
              />
              <span className="instruction">
                Please enter the email ID of the customer.
              </span>
              <input
                type="password"
                placeholder="Password"
                value={password}
                required
                onChange={(e) => setPassword(e.target.value)}
              />
              <span className="instruction">
                Please create a password with a minimum of 8 characters.
              </span>
              <input
                type="password"
                placeholder="Confirm Password"
                value={password2}
                required
                onChange={(e) => setPassword2(e.target.value)}
              />
              <span className="instruction">
                Please enter the password again for confirmation.
              </span>
              <span className="form-error error">
                {
                  getErrorMessage()
                }
              </span>
              <button
                type="button"
                onClick={handleRegister}
                disabled={disableRegistration()}
              >
                {showLoader? <ImSpinner5 className="spinner" /> : 'Register'}
              </button>
            </div>
          }
        </> :
        <>
          <div>
            <h2>Welcome</h2>
            <button type="button" onClick={handleLogout}>Logout</button>
          </div>
        </>
      }
    </div>
  );
}
