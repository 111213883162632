import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {fetchAllInvestments} from './investmentsAPI';

const initialState = {
  allInvestments: [],
  status: 'idle',
};

/**
 * Saves an investments to the server.
 * @param {object} allInvestments - The investments object to save.
 * @return {Promise<void>} A promise that resolves when
 * the investments has been saved.
 */
export const getAllInvestments = createAsyncThunk(
    'investments/getAllInvestments',
    async (uid) => {
      const response = await fetchAllInvestments(uid);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    },
);

export const investmentsSlice = createSlice({
  name: 'investments',
  initialState,
  // The `reducers` field lets us define reducers
  // and generate associated actions
  reducers: {},
  extraReducers: (builder) => {
    builder
        .addCase(getAllInvestments.pending, (state) => {
          state.status = 'loading';
        })
        .addCase(getAllInvestments.fulfilled, (state, action) => {
          state.status = 'idle';
          state.allInvestments = action.payload;
        });
  },
});

export const allInvestments = (state) => state.investments.allInvestments;

export default investmentsSlice.reducer;
