import React, {useEffect, useRef} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {AiOutlineUser} from 'react-icons/ai';
import {toggleDropdown} from '../../features/dropdownMenu/dropdownSlice';
import {logout, uid, role} from '../../features/user/userSlice';
import './DropDownMenu.css';

const UserDropdown = () => {
  const dispatch = useDispatch();
  const isOpen = useSelector((state) => state.dropdown.isOpen);
  const navigate = useNavigate();
  const user = useSelector(uid);
  const userRole = useSelector(role);

  const dropdownRef = useRef(null);
  const dropdownIconRef = useRef(null);

  const handleDropdownClick = () => {
    dispatch(toggleDropdown());
  };

  const handleLogoutClick = () => {
    if (user) {
      dispatch(logout());
    } else {
      navigate('/login?redirect=/products/ALL');
    }
    dispatch(toggleDropdown());
  };

  const handleOrdersClick = () => {
    dispatch(toggleDropdown());
    navigate('/orders');
  };

  const handleInvestmentClick = () => {
    dispatch(toggleDropdown());
    navigate('/investments');
  };

  const handleOutsideClick = (e) => {
    if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
      if (dropdownIconRef.current &&
        !dropdownIconRef.current.contains(e.target)) {
        dispatch(toggleDropdown());
      }
    }
  };

  useEffect(() => {
    window.addEventListener('mousedown', handleOutsideClick);
    return () => {
      window.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  return (
    <div className="user-dropdown">
      <div
        className="user_icon_box"
        onClick={handleDropdownClick}
        ref={dropdownIconRef}
      >
        <AiOutlineUser className='use_icon' />
        <span className="nav_text">Account</span>
      </div>
      {isOpen && (
        <div className="dropdown-menu-container" ref={dropdownRef}>
          <div className="dropdown-menu">
            <ul>
              <li onClick={handleOrdersClick}>Orders</li>
              {userRole === 'investor' &&
                <li onClick={handleInvestmentClick}>My Investment</li>
              }
              <li onClick={handleLogoutClick}>{user ? 'Logout' : 'Login'}</li>
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};

export default UserDropdown;
