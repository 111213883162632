import React, {useEffect} from 'react';
import './Checkout.css';
import {useSelector, useDispatch} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {
  Radio,
  Grid,
  Row,
  Col,
} from 'rsuite';
import {
  cart,
  addToFirebaseCart,
  cartId,
} from '../../features/products/productSlice';
import BasketItem from '../Basket/BasketItem';
import Address from '../Address/Address';
import NewAddress from '../Address/NewAddress';
import Footer from '../Footer/Footer';
import {
  uid,
  customerAddresses,
  selectedAddress,
  userPayload,
} from '../../features/user/userSlice';
import {saveOrder} from '../../features/order/orderSlice';
import {clearCart} from '../../features/products/productSlice';
import {allLocations} from '../../features/locations/locationSlice';
import {getDeliverySlot} from '../../util/delivery';
import {sumPrice} from '../../util/util';

/**
 * Custom hook that returns a function to handle saving the order
 *
 * @return {Function} Function that handles saving the order
 */
function useHandleSaveOrder() {
  const dispatch = useDispatch();
  const user = useSelector(uid);
  const userData = useSelector(userPayload);
  const address = useSelector(selectedAddress);
  const basket = useSelector(cart);

  const prices = sumPrice(basket);
  const totalCartPriceFinal = prices.totalPriceFinal;
  const totalCartPrice = prices.totalPrice;
  const CartShippingCharge = prices.shippingCharge;

  const addresses = useSelector(customerAddresses);
  const locationsList = useSelector(allLocations);
  const deliverySlotTimes = getDeliverySlot(addresses, locationsList);
  const handleSaveOrder = (event, navigate) => {
    if (user) {
      event.preventDefault();
      dispatch(saveOrder({
        user: {
          uid: user,
          email: userData.email,
        },
        basket,
        address,
        totalCartPriceFinal,
        totalCartPrice,
        CartShippingCharge,
        deliveryDateTime: deliverySlotTimes.deliveryDateTime,
      }));
      dispatch(clearCart());
      navigate('/confirmation');
    } else {
      navigate('/login?redirect=/checkout');
    }
  };
  return handleSaveOrder;
}

/**
 * Component that displays the checkout page
 *
 * @return {JSX.Element} Checkout component JSX
 */
export default function Checkout() {
  const basket = useSelector(cart);

  const prices = sumPrice(basket);
  const totalCartPriceFinal = prices.totalPriceFinal;
  const totalCartPrice = prices.totalPrice;
  const CartShippingCharge = prices.shippingCharge;

  const addresses = useSelector(customerAddresses);
  const savedCartId = useSelector(cartId);
  // const user = useAuth();
  const user = useSelector(uid);
  const showNewAddressOption =
    user &&
    addresses.length === 0 ?
    true :
    false;
  const navigate = useNavigate();
  const handleSaveOrder = useHandleSaveOrder();

  let isCartUpdated = false;

  const dispatch = useDispatch();
  useEffect(() => {
    if (!isCartUpdated && !savedCartId) {
      isCartUpdated = true;
      dispatch(addToFirebaseCart({basket, user, addresses}));
    }
  }, [basket, user, isCartUpdated, addresses]);

  return (
    <>
      <div className="checkout_container">
        <Grid fluid>
          <Row className="show-grid checkout_show_grid">
            <Col xs={24} sm={24} md={12}>
              <div className="checkout_container_left">
                <div className="checkout_box">
                  {showNewAddressOption ?
                    <NewAddress/> :
                    <div className="checkout_box_title">
                      <Address/>
                    </div>
                  }
                </div>
                <div className="checkout_box">
                  <div className="checkout_box_title">
                    <span className="checkout_subtitle">Payment Options</span>
                  </div>
                  <div className="checkout_confirm">
                    <Radio value="" checked>
                      Cash on Delivery
                    </Radio>
                  </div>
                  <button
                    className="basket_button_buy"
                    disabled={showNewAddressOption}
                    onClick={(event) => handleSaveOrder(event, navigate)}
                  >
                    {(user && user != null) ?
                      'Place order' : 'Login to proceed'
                    }
                  </button>
                </div>
              </div>
            </Col>
            <Col xs={24} sm={24} md={12}>
              <div className="checkout_container_right">
                {basket.length > 0 ? (
                  <div className="basket_container">
                    <div className="basket_itemBox">
                      <h5>Order Summary</h5>
                      <div className="checkout-total">
                        <span>Basket Value</span>
                        <span className='checkout-total-amount'>
                          ₹{totalCartPrice.toLocaleString()}
                        </span>
                      </div>
                      <div className="checkout-total">
                        <span>Delivery Charge</span>
                        <span className='checkout-total-amount'>
                          ₹{CartShippingCharge.toLocaleString()}
                        </span>
                      </div>
                      <span className="checkout-total-line"></span>
                      <div className="checkout-total">
                        <span>Total Amount Payable</span>
                        <span className='checkout-total-amount'>
                          ₹{totalCartPriceFinal.toLocaleString()}
                        </span>
                      </div>
                    </div>
                  </div>
                ): (
                  <div className="favorite_empty"></div>
                )}
              </div>
            </Col>
            <Col>
              <div className='basket-container'>
                {basket.map((product) => (
                  <BasketItem key={product.id} {...product} />
                ))}
              </div>
            </Col>
          </Row>
        </Grid>
      </div>
      <Footer tab={'checkout'}/>
    </>
  );
}
