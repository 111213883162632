import React from 'react';
import {useSelector} from 'react-redux';
import './Investments.css';
import {Container, Content, Message} from 'rsuite';
import {investments} from '../../features/user/userSlice';
/**
 * Component that displays the login page
 *
 * @return {JSX.Element} Login component JSX
 */
export default function Investments() {
  const userInvestments = useSelector(investments);
  const returns = userInvestments.length > 0 &&
    userInvestments[0].returns ?
    userInvestments[0].returns : [];
  const totalReturns =
    returns.reduce((total, investment) => total + investment.amount, 0);


  return (
    <Container className="investorContainer">
      <Content>
        {userInvestments.length > 0 && userInvestments.map((item, index) => (
          <div key={index}>
            <Message>
              <h6>
                {item.investmentName}
              </h6>
              {item.investmentDescription}
              <span className='investmentLines'>
                <b>Total Investments</b> ₹ {item.investedAmount}
              </span>
              <span className='investmentLines'>
                <b>Total Total Return</b> ₹ {totalReturns}
              </span>
              <span className='investmentLines'>
                <b>Balance</b> ₹ {item.balancePayment}
              </span>
            </Message>
            <Message>
              <h6>
                Activities
              </h6>
              {returns.map((item, index) => (
                <div key={index} className='activities'>
                  <span className='investmentLines'>
                    <b>{item.title}</b>
                    <br></br>
                    {item.description}
                    <br></br>
                    { item.amount > 0 && <span>Returns: ₹ {item.amount}</span>}
                  </span>
                </div>
              ))}
            </Message>
          </div>
        ))}
      </Content>
    </Container>
  );
}
