import React from 'react';
import './Basket.css';
import {Link} from 'react-router-dom';
import {useSelector, useDispatch} from 'react-redux';
import {HiArrowLeft} from 'react-icons/hi';
import BasketItem from './BasketItem';
import SendProducts from './SendProducts';
import {
  cart,
  clearCart,
} from '../../features/products/productSlice';
import {freeShippingLimit} from '../../config/constants';
import {uid} from '../../features/user/userSlice';
import {sumPrice} from '../../util/util';

/**
 * Component that displays the basket page
 *
 * @return {JSX.Element} Basket component JSX
 */
export default function Basket() {
  const dispatch = useDispatch();
  const basket = useSelector(cart);

  const prices = sumPrice(basket);
  const totalCartPrice = prices.totalPrice;
  const totalCartPriceAfterOffer = prices.totalPriceAfterOffer;
  const totalCartPriceFinal = prices.totalPriceFinal;

  // const user = useAuth();
  const user = useSelector(uid);
  return (
    <>
      <div className="favorite_container_linkBar">
        <div className="favorite_linkBar">
          <Link className="favorite_backLink" to={'/'}>
            <HiArrowLeft />
            Products page
          </Link>
          <span>Shopping cart</span>
        </div>
        {basket.length > 0 && (
          <div className="favorite_linkBar">
            <div className="free_send_title">
              <img src="images/sound(1).jpg" alt="" />
              <span>
                Shipping is free for purchases over Rs. {freeShippingLimit}
              </span>
            </div>
          </div>
        )}
      </div>
      {basket.length > 0 ? (
        <div className="basket_container">
          <div className="basket_itemBox">
            {basket.map((product) => (
              <BasketItem key={product.optionId} {...product} />
            ))}
          </div>
          <div className="basket_priceBox">
            <div className="basket_price">
              <span>Total shopping cart</span>
              <span>|</span>
              <span>Rs {totalCartPrice.toLocaleString()}</span>
            </div>
            {totalCartPriceAfterOffer > 0 && (
              <div className="basket_offer">
                <span>Discounted price</span>
                <span>Rs {totalCartPriceAfterOffer.toLocaleString()}</span>
              </div>
            )}
            <SendProducts />
            <div className="basket_send">
              <span>Total amount payable</span>
              <span>Rs {totalCartPriceFinal.toLocaleString()}</span>
            </div>
            <Link
              className="basket_button_buy"
              to={{
                pathname: user ? '/checkout' : '/login',
                search: 'redirect=/checkout',
              }}
            >
              Checkout
            </Link>
            <button
              onClick={() => dispatch(clearCart())}
              className="basket_button_remove"
            >
              Delete {basket.length} Goods from the shopping cart
            </button>
          </div>
        </div>
      ) : (
        <div className="favorite_empty">
          <span className="favorite_empty_title">
            The shopping cart is empty
          </span>
        </div>
      )}
    </>
  );
}
