import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {
  fetchProducts,
  fetchCacheKeys,
  createOrUpdateCart,
  markCartSuccess,
} from './productsAPI';
import {
  productCacheExpiration,
} from '../../config/constants';

const persistedState = JSON.parse(localStorage.getItem('persist:root')) || [];
const persistedProducts = persistedState.products ?
  JSON.parse(persistedState.products) :
  {};
const persistedBasket = persistedProducts.basket ?
  persistedProducts.basket :
  [];
const persistedCacheKey = persistedProducts.cacheKey;
const persistedCartId = persistedProducts.cartId;

const initialState = {
  allProducts: [],
  allOptions: [],
  status: 'idle',
  basket: persistedBasket,
  isFavorite: false,
  isEnterOfferCode: false,
  offerMessage: '',
  openHalfCart: false,
  searchKey: '',
  cacheKey: persistedCacheKey,
  cartId: persistedCartId,
};

export const getProducts = createAsyncThunk(
    'products/fetchProducts',
    async (category) => {
      const cacheKeys = await fetchCacheKeys();
      const productsCacheKey = cacheKeys.products;
      if (persistedProducts.allProducts &&
        persistedProducts.allProducts.length > 0 &&
        persistedProducts.cacheKey === productsCacheKey) {
        return {
          products: {
            data: persistedProducts.allProducts,
          },
          options: {
            data: persistedProducts.allOptions,
          },
          expiry: persistedProducts.expiry,
          cacheKey: productsCacheKey,
        };
      } else {
        const response = await fetchProducts(category);
        return {
          ...response.data,
          expiry: new Date().getTime() + productCacheExpiration,
          cacheKey: productsCacheKey,
        };
      }
    },
);

export const addToFirebaseCart = createAsyncThunk(
    'products/addToFirebaseCart',
    async (data) => {
      const response = await createOrUpdateCart(
          data.basket,
          data.user,
          data.addresses,
      );
      return response;
    },
);

export const cartSuccess = createAsyncThunk(
    'products/cartSuccess',
    async (cartId) => {
      return markCartSuccess(cartId);
    },
);

export const productSlice = createSlice({
  name: 'products',
  initialState,
  // The `reducers` field lets us define reducers
  // and generate associated actions
  reducers: {
    addToCart: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.basket.push({...action.payload, count: 1});
    },
    increaseCartQty: (state, action) => {
      const indexPlus = state.basket.findIndex(
          (product) => product.optionId === action.payload,
      );
      state.basket[indexPlus].count++;
    },
    decreaseCartQty: (state, action) => {
      const indexMinus = state.basket.findIndex(
          (product) => product.optionId === action.payload,
      );
      if (state.basket[indexMinus].count > 1) {
        state.basket[indexMinus].count--;
      } else {
        state.basket.splice(indexMinus, 1);
      }
    },
    clearCart: (state) => {
      state.basket = [];
      const cartIdCopy = state.cartId;
      state.cartId = null;
      markCartSuccess(cartIdCopy);
    },
    showHalfCart: (state, action) => {
      state.openHalfCart = action.payload;
    },
    setSearchKey: (state, action) => {
      state.searchKey = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
        .addCase(getProducts.pending, (state) => {
          state.status = 'loading';
        })
        .addCase(getProducts.fulfilled, (state, action) => {
          state.status = 'idle';
          const payload = action.payload;
          state.allProducts = payload.products.data;
          state.expiry = payload.expiry;
          state.cacheKey = payload.cacheKey;
        })
        .addCase(addToFirebaseCart.fulfilled, (state, action) => {
          state.cartId = action.payload.cartId;
        })
        .addCase(cartSuccess.fulfilled, (state, action) => {
          state.cartId = null;
        });
  },
});

export const {
  addToCart,
  increaseCartQty,
  decreaseCartQty,
  clearCart,
  showHalfCart,
  setSearchKey,
} = productSlice.actions;
export const allProducts = (state) => state.products.allProducts;
export const productsStatus = (state) => state.products.status;
export const cart = (state) => state.products.basket;
export const isFavorite = (state) => state.products.isFavorite;
export const isEnterOfferCode = (state) => state.products.isEnterOfferCode;
export const offerMessage = (state) => state.products.offerMessage;
export const allOptions = (state) => state.products.allOptions;
export const openHalfCart = (state) => state.products.openHalfCart;
export const searchKey = (state) => state.products.searchKey;
export const cartId = (state) => state.products.cartId;

export default productSlice.reducer;
