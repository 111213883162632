import React from 'react';
import {useNavigate} from 'react-router-dom';
import {Carousel} from 'rsuite';
import './Banner.css';

/**
 * Component that displays the Footer page
 *
 * @return {JSX.Element} Footer component JSX
 */
export default function Banner() {
  const navigate = useNavigate();
  const handleFarmVisitClick = () => {
    navigate('/event/farm_visit');
  };
  return (
    <div className="banner">
      <div className="banner_content">
        <Carousel autoplay className="banner_slider">
          <img
            src='/images/banners/farmevent.jpg'
            alt='Visit our farmer'
            onClick={handleFarmVisitClick}
          />
        </Carousel>
      </div>
    </div>
  );
}
