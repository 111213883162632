import React, {useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import PropTypes from 'prop-types';
import './Orders.css';
import {
  AiOutlineDown,
  AiOutlineUp,
  AiOutlineFieldTime,
  AiOutlineCloseCircle,
} from 'react-icons/ai';
import {
  GrStatusGood,
  GrLocation,
} from 'react-icons/gr';
import {Modal, Button} from 'rsuite';
import {
  cancelCustomerOrder,
  orderCancelStatus,
} from '../../features/order/orderSlice';

/**
 * Component that displays the order page
 *
 * @param {Object} props - The props object containing product information
 * @return {JSX.Element} Order component JSX
 */
export default function Order(props) {
  const {
    id,
    createdAt,
    address,
    items,
    status,
  } = props;

  const shippingCharge = props.shipping_charge;
  const totalPrice = props.total_price;
  const totalPriceFinal = props.total_price_final;
  const phoneNumber = props.phone_number;

  const timestamp = createdAt.toDate();
  const formattedDate = timestamp.toLocaleString();
  const dispatch = useDispatch();
  const cancelStatus = useSelector(orderCancelStatus);
  const orderStatus = cancelStatus.status === 'success' &&
    cancelStatus.order.orderId === id ? cancelStatus.order.status : status;

  const handleSaveOrder = (orderId) => {
    setOpen(false);
    dispatch(cancelCustomerOrder({orderId}));
  };

  const [showDetails, setShowDetails] = useState(false);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  // created, confirmed, delivered, canceled, refunded
  return (
    <>
      <div className="order-item">
        {orderStatus === 'created' ?
        (<Button
          className="cancel-button" appearance="ghost"
          onClick={handleOpen}
        >
          Cancel
        </Button>) : null}
        <span className="order-item-title">Order # {id}</span>
        <span className="order-item-title">{formattedDate}</span>
        <span className="order-item-title">₹ {totalPriceFinal}</span>
        <div className="order-item-status">
          {orderStatus === 'created' || orderStatus === 'confirmed' ?
            <AiOutlineFieldTime className='status-icon'/> :
            null
          }
          {orderStatus != 'created' &&
            orderStatus != 'confirmed' && orderStatus != 'canceled' ?
            <GrStatusGood className='status-icon'/> :
            null
          }
          {orderStatus === 'canceled' ?
            <AiOutlineCloseCircle className='status-icon'/> :
            null
          }
          <span className='status-text'>{orderStatus}</span>
        </div>
        <div className= {showDetails ? '' : 'order-item-details'}>
          <div className="order-item-address">
            <GrLocation/>
            {address.name},
            {address.apartmentName},
            {address.flatNo},
            <br/>
            {address.addressLine1},
            {address.addressLine2},
            <br/>
            {address.city},
            {address.state},
            {address.pinCode}
            <br/>
            {phoneNumber}
          </div>
          <div>
            {items.map((item) => (
              <div
                key= {item.optionId}
                className='order-product'
              >
                {item.title} ({item.optionTitle}) x {item.count}&nbsp;
                = ₹ {item.sellPrice}
              </div>
            ))}
          </div>
          <div>Shipping charge: {shippingCharge}</div>
          <div className="order-item-title">Total: {totalPrice}</div>
        </div>
        {showDetails ? (
          <AiOutlineUp className='order-down-arrow' onClick={
            () => setShowDetails(false)
          }/>
        ): (<AiOutlineDown className='order-down-arrow' onClick={
          () => setShowDetails(true)
        }/>)}
      </div>
      <Modal
        backdrop="static"
        role="alertdialog"
        open={open}
        onClose={handleClose}
        size="xs"
      >
        <Modal.Body>
          Are you sure you want to cancel this order?
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => handleSaveOrder(id)} appearance="subtle">
            Yes
          </Button>
          <Button onClick={handleClose} appearance="primary">
            No
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

Order.propTypes = {
  id: PropTypes.string.isRequired,
  createdAt: PropTypes.object.isRequired,
  shipping_charge: PropTypes.number,
  address: PropTypes.object,
  items: PropTypes.array,
  status: PropTypes.string,
  shipping_charge: PropTypes.number,
  total_price: PropTypes.number,
  total_price_final: PropTypes.number,
  phone_number: PropTypes.string,
};
