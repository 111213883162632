import {initializeApp} from 'firebase/app';
import {getFirestore} from 'firebase/firestore';

// firebase configuration
// staging
// import {firebaseConfig} from './config/firebaseConfigStaging';
// production
import {firebaseConfig} from './config/firebaseConfigProduction';

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);

// Create Firestore instance
const db = getFirestore(firebaseApp);

export {firebaseApp};
export default db;
