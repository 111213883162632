import {combineReducers} from 'redux';
import {configureStore} from '@reduxjs/toolkit';
import {persistStore, persistReducer} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import productReducer from '../features/products/productSlice';
import userSlice from '../features/user/userSlice';
import locationSlice from '../features/locations/locationSlice';
import orderSlice from '../features/order/orderSlice';
import dropdownSlice from '../features/dropdownMenu/dropdownSlice';
import investmentsSlice from '../features/investments/investmentsSlice';
import eventsSlice from '../features/events/eventsSlice';

const persistConfig = {
  key: 'root',
  storage,
  // expire: 24 * 60 * 60 * 1000, // 24 hours in milliseconds
  expire: 300000,
};

const rootReducer = combineReducers({
  products: productReducer,
  user: userSlice,
  locations: locationSlice,
  order: orderSlice,
  dropdown: dropdownSlice,
  investmentsSlice: investmentsSlice,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: {
    products: productReducer,
    user: userSlice,
    locations: locationSlice,
    dropdown: dropdownSlice,
    order: orderSlice,
    investments: investmentsSlice,
    events: eventsSlice,
    persisted: persistedReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false, // Disable serializable check
    }),
});

// create a persisted version of the store
export const persistedStore = persistStore(store);
