import {createSlice} from '@reduxjs/toolkit';

const dropdownSlice = createSlice({
  name: 'dropdown',
  initialState: {
    isOpen: false,
  },
  reducers: {
    toggleDropdown: (state) => {
      state.isOpen = !state.isOpen;
    },
  },
});

export const {toggleDropdown} = dropdownSlice.actions;
export default dropdownSlice.reducer;
