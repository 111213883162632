import React from 'react';
import PropTypes from 'prop-types';

/**
 * Component that displays the Card page
 *
 * @param {Object} props - The props object containing product information
 * @return {JSX.Element} Card component JSX
 */
export default function Card(props) {
  const image = `/images/products/${props.image === '' ?
    'default_prod.jpg' :
    props.image}`;
  return (
    <div key={props.id} className="price-list">
      <img className="product-img" src={image} alt="product" />
      <div className="price-list-content">
        <div className="title">
          <div className="multi-variant-title">
            {props.title}
          </div>
          {props.product_options && (
            <div className="variant-options">
              {props.product_options.map((option, index) => (
                option.weight_ratio === 1 && (
                  <div key={index} className="option">
                    <div>{option.optionTitle}</div>
                    <div>{option.sellPrice}</div>
                  </div>
                )
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

Card.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  optionId: PropTypes.string,
  qty_available: PropTypes.number,
  product_options: PropTypes.array,
  isMultiVariantProduct: PropTypes.bool.isRequired,
};
