import React from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {HiArrowRight} from 'react-icons/hi';
import './Details.css';
import Buttons from '../Buttons/Buttons';

/**
 * Component that displays the Details page
 *
 * @return {JSX.Element} Details component JSX
 */
export default function Details() {
  const navigate = useNavigate();

  const state = {
    basket: [],
    totalPrice: 100,
    offerPrice: 100,
    totalPriceFinal: 100,
  };
  const dispatch = () => {};


  const params = useParams();
  const datas = state.allProducts.find((product) => product.id == params.id);
  const checkBasket = state.basket.some((product) => product.id == params.id);

  return (
    <div className="details_container">
      <div className="details_linkBar">
        <span onClick={() => navigate(-1)} className="details_backLink">
          <HiArrowRight />
          go back
        </span>
      </div>
      <div className="datails_card">
        <div className="image_box">
          <img className="card_image" src={datas.image} alt="card_image" />
          <img src="/images/kg.png" alt="" className="kg_image" />
        </div>
        <div className="main_content_box">
          <span className="card_category">{datas.category}</span>
          <div className="card_content">
            <span className="card_title">{datas.title}</span>
            <span style={{color: '#409f7a'}}>|</span>
            <span className="card_price">
              Rs {datas.price.toLocaleString()}
            </span>
          </div>
          <div className="card_information">
            <ul>
              <li>Organic: Yes</li>
              <li>Packaging weight: one kilogram</li>
            </ul>
          </div>
          {checkBasket && <Buttons {...datas} />}
          <button
            onClick={() =>
              dispatch({type: 'ADD_TO_BASKET', payload: datas.id})
            }
            className="card_buy"
          >
            add to cart
          </button>
        </div>
      </div>
    </div>
  );
}
