import React, {useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import './Address.css';
import {
  customerAddresses,
} from '../../features/user/userSlice';
import {
  getLocations,
  allLocations,
} from '../../features/locations/locationSlice';
import {getDeliverySlot} from '../../util/delivery';

/**
 * Component that displays the Address page
 *
 * @return {JSX.Element} Address component JSX
 */
function Address() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getLocations());
  }, []);

  const addresses = useSelector(customerAddresses);
  const address = addresses.length > 0 ? addresses[0] : {};
  let deliverySlot = '';
  try {
    const locationsList = useSelector(allLocations);
    const deliverySlotTimes = getDeliverySlot(addresses, locationsList);
    if (deliverySlotTimes) {
      // eslint-disable-next-line max-len
      deliverySlot = `${deliverySlotTimes.day} between ${deliverySlotTimes.from} and ${deliverySlotTimes.to}`;
    }
  } catch (err) {
    console.error(err);
  }

  return (
    <div className="address_container">
      <h5>Delivery Address</h5>
      <span>{address.name}</span>
      <span>{address.flatNo} {address.apartmentName}</span>
      <span>{address.addressLine1}</span>
      <span>{address.addressLine2}</span>
      <span>{address.city} {address.pinCode}</span>
      <span>{address.state}</span>
      <br /><br />
      Will be delivered on {deliverySlot}
    </div>
  );
}

export default Address;
