import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {
  fetchAllOrders,
  fetchOrder,
  placeOrder,
  cancelOrder} from './orderAPI';
import {
  Timestamp,
} from 'firebase/firestore';

const initialState = {
  allOrders: [],
  status: 'idle',
  selectedOrder: {},
  currentOrder: {},
  createOrderStatus: 'idle',
  orderFetchStatus: 'idle',
  orderCancelStatus: 'idle',
};

/**
 * Saves an order to the server.
 * @param {object} allOrders - The order object to save.
 * @return {Promise<void>} A promise that resolves when
 * the order has been saved.
 */
export const getAllOrders = createAsyncThunk(
    'order/fetchAllOrders',
    async (uid) => {
      const response = await fetchAllOrders(uid);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    },
);

/**
 * Saves an order to the server.
 * @param {object} selectedOrder - The order object to save.
 * @return {Promise<void>} A promise that resolves when the
 * order has been saved.
 */
export const getSelectedOrder = createAsyncThunk(
    'order/fetchOrder',
    async (orderId) => {
      return await fetchOrder(orderId);
    },
);

/**
 * Saves an order to the server.
 * @param {object} currentOrder - The order object to save.
 * @return {Promise<void>} A promise that resolves when the
 * order has been saved.
 */
export const saveOrder = createAsyncThunk(
    'order/placeOrder',
    async ({
      user,
      basket,
      address,
      totalCartPriceFinal,
      totalCartPrice,
      CartShippingCharge,
      deliveryDateTime,
    }) => {
      const order = {
        uid: user.uid,
        email: user.email,
        items: basket,
        total_price_final: totalCartPriceFinal,
        total_price: totalCartPrice,
        shipping_charge: CartShippingCharge,
        address,
        status: 'created', // created, confirmed, fulfilled, canceled, refunded
        deliveryDateTime: Timestamp.fromDate(deliveryDateTime),
      };
      return await placeOrder(order);
    },
);

/**
 * Saves an order to the server.
 * @param {object} orderId - The order object to save.
 * @return {Promise<void>} A promise that resolves when the
 * order has been saved.
 */
export const cancelCustomerOrder = createAsyncThunk(
    'order/cancelCustomerOrder',
    async ({
      orderId,
    }) => {
      return await cancelOrder(orderId);
    },
);

export const orderSlice = createSlice({
  name: 'order',
  initialState,
  // The `reducers` field lets us define reducers
  // and generate associated actions
  reducers: {},
  extraReducers: (builder) => {
    builder
        .addCase(getAllOrders.pending, (state) => {
          state.orderFetchStatus = 'pending';
        })
        .addCase(getAllOrders.fulfilled, (state, action) => {
          state.orderFetchStatus = 'fulfilled';
          state.allOrders = action.payload;
        })
        .addCase(getSelectedOrder.pending, (state) => {
          state.status = 'pending';
        })
        .addCase(getSelectedOrder.fulfilled, (state, action) => {
          state.status = 'fulfilled';
          state.selectedOrder = action.payload;
        })
        .addCase(saveOrder.pending, (state) => {
          state.createOrderStatus = 'pending';
        })
        .addCase(saveOrder.fulfilled, (state, action) => {
          if (action.payload.status === 'failed') {
            state.createOrderStatus = 'failed';
          } else {
            state.createOrderStatus = 'created';
            state.currentOrder = action.payload.order;
          }
        })
        .addCase(cancelCustomerOrder.pending, (state) => {
          state.orderCancelStatus = 'pending';
        })
        .addCase(cancelCustomerOrder.fulfilled, (state, action) => {
          state.orderCancelStatus = action.payload;
        });
  },
});

export const allOrders = (state) => state.order.allOrders;
export const selectedOrder = (state) => state.order.selectedOrder;
export const currentOrder = (state) => state.order.currentOrder;
export const createOrderStatus = (state) => state.order.createOrderStatus;
export const orderFetchStatus = (state) => state.order.orderFetchStatus;
export const orderCancelStatus = (state) => state.order.orderCancelStatus;

export default orderSlice.reducer;
