import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {
  placeEvent,
} from './eventsAPI';

const initialState = {
  status: 'idle',
  eventsCreateStatus: 'idle',
};

/**
 * Saves an events to the server.
 * @param {object} currentEvents - The events object to save.
 * @return {Promise<void>} A promise that resolves when the
 * events has been saved.
 */
export const saveEvents = createAsyncThunk(
    'events/placeEvents',
    async (event) => {
      return await placeEvent(event);
    },
);

export const eventsSlice = createSlice({
  name: 'events',
  initialState,
  // The `reducers` field lets us define reducers
  // and generate associated actions
  reducers: {},
  extraReducers: (builder) => {
    builder
        .addCase(saveEvents.pending, (state) => {
          state.eventsCreateStatus = 'pending';
        })
        .addCase(saveEvents.fulfilled, (state, action) => {
          state.eventsCreateStatus = 'fulfilled';
          state.allEvents = action.payload;
        });
  },
});

export const allEvents = (state) => state.events.allEvents;
export const eventsCreateStatus = (state) => state.events.eventsCreateStatus;

export default eventsSlice.reducer;
