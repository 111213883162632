import db from '../../firebaseConfig';
import {
  collection,
  getDocs,
  query,
  where,
} from 'firebase/firestore';

const fetchInvestments = async (uid) => {
  const res = [];
  const q =
    query(collection(db, 'investments'), where('users', 'array-contains', uid));
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    const data = doc.data();
    res.push({id: doc.id, ...data});
  });
  return {data: res};
};

/**
 * Fetches all investments.
 *
 * @param {string} uid - The string uid to fetch all investments.
 * @return {Promise} A promise that resolves with all investments.
 */
export function fetchAllInvestments(uid) {
  return new Promise((resolve) =>
    resolve(fetchInvestments(uid)),
  );
}
