import React from 'react';
import LoginEmail from './LoginEmail';
import './Login.css';
// import LoginMobile from './LoginMobile';
import Footer from '../Footer/Footer';

/**
 * Component that displays the login page
 *
 * @return {JSX.Element} Login component JSX
 */
export default function Login() {
  return (
    <>
      <LoginEmail/>
      <Footer tab={'login'}/>
    </>
  );
}
