import React from 'react';
import {AiOutlinePlus, AiOutlineMinus} from 'react-icons/ai';
import {RiDeleteBinLine} from 'react-icons/ri';
import {useSelector, useDispatch} from 'react-redux';
import PropTypes from 'prop-types';
import {
  cart,
  increaseCartQty,
  decreaseCartQty,
} from '../../features/products/productSlice';
import './Buttons.css';

/**
 * Component that displays the Buttons page
 *
 * @param {Object} props - The props object containing product information
 * @return {JSX.Element} Buttons component JSX
 */
export default function Buttons(props) {
  const dispatch = useDispatch();
  const basket = useSelector(cart);
  const {optionId} = props;
  const countItem = basket.find((product) => product.optionId === optionId);
  return (
    <div className="basket_buttons">
      {countItem.count === 1 ? (
        <span
          onClick={() => dispatch(decreaseCartQty(optionId))}
          className="basket_minus"
        >
          <RiDeleteBinLine />
        </span>
      ) : (
        <span
          onClick={() => dispatch(decreaseCartQty(optionId))}
          className="basket_minus"
        >
          <AiOutlineMinus />
        </span>
      )}
      <span className="counter_number">{countItem.count}</span>
      <span
        onClick={() => dispatch(increaseCartQty(optionId))}
        className="basket_plus"
      >
        <AiOutlinePlus />
      </span>
    </div>
  );
}

Buttons.propTypes = {
  optionId: PropTypes.string.isRequired,
};
