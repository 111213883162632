import React, {useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {
  getProducts,
  allProducts,
} from '../../features/products/productSlice';
import './PriceAndStockList.css';
import Footer from '../Footer/Footer';
import Card from './Card';

/**
 * Component that displays the products page
 *
 * @return {JSX.Element} Products component JSX
 */
export default function PriceAndStockList() {
  const productsList = useSelector(allProducts);
  const sortedProducts =
    productsList.slice().sort(
        (a, b) => a.internal_reference - b.internal_reference,
    );
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getProducts());
  }, []);
  return (
    <>
      {sortedProducts.map(
          (product) => <Card key={product.id} {...product} />,
      )}
      <Footer tab={'products'}/>
    </>
  );
}
