import db from '../../firebaseConfig';
import {
  collection,
  getDocs,
  query,
} from 'firebase/firestore';

const fetchLocations = async () => {
  const res = [];
  const q = query(collection(db, 'active_locations'));
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    const data = doc.data();
    res.push({id: doc.id, ...data});
  });
  return {data: res};
};

/**
 * Fetches all locations.
 *
 * @return {Promise} A promise that resolves with the locations.
 */
export function fetchAllLocation() {
  return new Promise((resolve) =>
    resolve(fetchLocations()),
  );
}
