import React from 'react';
import './FooterBanner.css';
import {
  FaHome,
  FaShoppingBag,
  FaShoppingCart,
  FaUser,
} from 'react-icons/fa';
import {useSelector, useDispatch} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import PropTypes from 'prop-types';
import {cart, showHalfCart} from '../../features/products/productSlice';
import {uid} from '../../features/user/userSlice';

/**
 * Component that displays the Footer page
 * @param {Object} props - The properties passed to the component.
 * @param {String} props.tab - The tab prop.
 * @return {JSX.Element} Footer component JSX
 */
export default function Footer({tab}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const basket = useSelector(cart);
  const user = useSelector(uid);

  const handleCartClick = () => {
    dispatch(showHalfCart(true));
  };

  const handleOrdersClick = () => {
    navigate('/orders');
  };

  const handleHomeClick = () => {
    navigate('/');
  };

  const handleUserClick = () => {
    if (user) {
      navigate('/account');
    } else {
      navigate('/login');
    }
  };

  // eslint-disable-next-line max-len
  const homeClass = tab === 'products' ? 'footer_menu_item active' : 'footer_menu_item';
  // eslint-disable-next-line max-len
  const cartClass = tab === 'checkout' ? 'footer_menu_item active' : 'footer_menu_item';
  // eslint-disable-next-line max-len
  const OrderClass = tab === 'orders' ? 'footer_menu_item active' : 'footer_menu_item';
  // eslint-disable-next-line max-len
  const loginClass = tab === 'login' ? 'footer_menu_item active' : 'footer_menu_item';

  return (
    <>
      <div className="footer_menu">
        <div className="footer_menu_content">
          <div className={homeClass} onClick={handleHomeClick}>
            <FaHome/>
            <span>Home</span>
          </div>
          <div className={cartClass} onClick={handleCartClick}>
            {basket.length > 0 && (
              <span className="badge_shope">{basket.length}</span>
            )}
            <FaShoppingCart/>
            <span>Cart</span>
          </div>
          <div className="footer_menu_item_round">
            <img src="/images/app_logo.png" alt="" />
          </div>
          <div className={OrderClass} onClick={handleOrdersClick}>
            <FaShoppingBag/>
            <span>Orders</span>
          </div>
          <div className={loginClass} onClick={handleUserClick}>
            <FaUser/>
            <span>Account</span>
          </div>
        </div>
      </div>
    </>
  );
}

// PropTypes validation
Footer.propTypes = {
  tab: PropTypes.string.isRequired,
};
