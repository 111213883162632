import React from 'react';
import {useDispatch} from 'react-redux';
import {useNavigate, useParams} from 'react-router-dom';
import './Filter.css';
import {
  setSearchKey,
} from '../../../features/products/productSlice';
import {
  categories,
  CategoryTranslations,
  CategoryImages,
} from './constants';

/**
 * Component that displays the filter page
 *
 * @return {JSX.Element} Filter component JSX
 */
export default function FilterWithImage() {
  const dispatch = useDispatch();
  const {id: selectedCat} = useParams();
  const navigate = useNavigate();

  const handleFilterClick = (category) => {
    dispatch(setSearchKey(''));
    navigate(`/products/${category}`);
  };

  return (
    <>
      <div className="filter_title">Explore our categories</div>
      <div className="filter_image_container">
        {categories.map(({category}) => (
          category !== 'ALL' && (
            <div className="filter_item" key={category}>
              <img
                src={'/images/category/'+CategoryImages[category]}
                onClick={() => handleFilterClick(category)}
                key={`img_${category}`}
              ></img>
              <span
                key={`text_${category}`}
                onClick={() => handleFilterClick(category)}
                className= {
                  selectedCat === category ?
                  'filter_text_selected' :
                  'filter_text'
                }
              >
                {CategoryTranslations[category]}
              </span>
            </div>
          )))}
      </div>
    </>
  );
}
