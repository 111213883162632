import React from 'react';
import './ContentPages.css';
import Footer from '../Footer/Footer';
/**
 * Component that displays the Footer page
 *
 * @return {JSX.Element} Footer component JSX
 */
export default function ContentPages() {
  return (
    <>
      <div className='content'>
        <a href="https://www.freepik.com/free-vector/agricultural-cereals-set_5973040.htm#fromView=search&term=staples&track=sph&regularType=vector&page=1&position=5&uuid=5d53c1a7-1744-4d5f-b96e-48e44bbcceca">
          Image by macrovector
        </a> on Freepik
        <br/>
        <a href="https://www.freepik.com/free-vector/cup-with-nuts_3924884.htm#fromView=search&term=dry+fruits&track=ais&regularType=vector&page=1&position=8&uuid=42ed2732-5b2f-4805-9025-b6d6abc95da1">Image by macrovector</a> on Freepik
        <br/>
        <a href="https://www.freepik.com/free-vector/dairy-products-realistic-composition_6438604.htm#fromView=search&term=milk+products&track=ais&regularType=vector&page=1&position=52&uuid=6092377b-53e9-43f4-90f7-5ff02f2d68e9">Image by macrovector</a> on Freepik
        <br/>
        <a href="https://www.freepik.com/free-vector/vegetarian-organic-food-advertising-design-concept-with-sunflower-oil-pouring-from-plastic-bottle-realistic-vector-illustration_29169805.htm#fromView=search&term=edible+oil&track=ais&regularType=vector&page=1&position=32&uuid=6b28268d-148c-4820-b68e-4c16047a03e4">Image by macrovector</a> on Freepik
        <br/>
        <a href="https://www.freepik.com/free-vector/mustard-realistic-composition-with-isolated-image-rapeseed-oil-bottle-blank-background-vector-illustration_23129139.htm#fromView=search&term=edible+oil&track=ais&regularType=vector&page=1&position=35&uuid=6b28268d-148c-4820-b68e-4c16047a03e4">Image by macrovector</a> on Freepik
        <br/>
        <a href="https://www.freepik.com/free-vector/honey-dripping-from-dipper-stick-with-honeybee-glass-jar-realistic-design-concept-white-background_4386004.htm#fromView=search&term=honey&track=sph&regularType=vector&page=1&position=5&uuid=fe1d7849-9d31-449d-bbdc-46edee162527">Image by macrovector</a> on Freepik
        <br/>
        <a href="https://www.freepik.com/free-vector/honey-stick-illustration_3887091.htm#fromView=search&term=honey&track=sph&regularType=vector&page=1&position=7&uuid=fe1d7849-9d31-449d-bbdc-46edee162527">Image by macrovector</a> on Freepik
        <br/>
        <a href="https://www.freepik.com/free-vector/realistic-bunch-fresh-green-leafy-vegetables-spicy-herbs-with-arugula-spinach-thyme-white_6851763.htm#fromView=search&term=palak&track=sph&regularType=vector&page=1&position=4&uuid=8041a9c0-45e5-4ac8-9059-b4f2d3cd8444">Image by macrovector</a> on Freepik
        <br/>
        <a href="https://www.freepik.com/free-vector/realistic-chrysanthemum-bouquet-with-colorful-flower-bulbs-dark-background-vector-illustration_41684351.htm#page=2&query=flowers&position=9&from_view=author&uuid=87defafc-67d0-4f4b-8418-3ff638f2ee2c">Image by macrovector</a> on Freepik
        <br/>
        <a href="https://www.freepik.com/free-vector/harvest-vegetables-illustration_4279373.htm#query=vegitables&position=10&from_view=author&uuid=373d17a8-ed0f-402b-b059-31fa93b61825">Image by macrovector</a> on Freepik
        <br/>
        <a href="https://www.freepik.com/free-vector/vegetables-realistic-composition-white-background-with-tomato-onion-sweet-hot-pepper-eggplant_4029361.htm#query=vegitables&position=3&from_view=author&uuid=373d17a8-ed0f-402b-b059-31fa93b61825">Image by macrovector</a> on Freepik
        <br/>
        <a href="https://www.freepik.com/free-vector/realistic-juicy-fruit-illustration_5975370.htm#fromView=search&term=fruits&track=sph&regularType=vector&page=1&position=3&uuid=49863a61-1699-422b-acea-73ce932fbf76">Image by macrovector</a> on Freepik
        <br/>
        <a href="https://www.freepik.com/free-vector/vegetables-realistic-composition-white-background-with-tomato-onion-sweet-hot-pepper-eggplant_4029361.htm#fromView=search&term=vegetables&track=sph&regularType=vector&page=1&position=2&uuid=94665dd3-8b7f-46a5-bb34-529197e82da9">Image by macrovector</a> on Freepik
        <br/>
        <a href="https://www.freepik.com/free-vector/vegetables-realistic-composition_6883821.htm#page=3&query=vegetables%20indian&position=46&from_view=search&track=ais&uuid=45ec6691-9217-4fe2-8e60-df51179834b3">Image by macrovector</a> on Freepik
        <br/>
        Image by <a href="https://www.freepik.com/free-photo/top-view-basket-with-assortment-vegetables_10300545.htm#page=2&query=vegetables%20indian&position=3&from_view=search&track=ais&uuid=45ec6691-9217-4fe2-8e60-df51179834b3">Freepik</a>
        <br/>
        Image by <a href="https://www.freepik.com/free-vector/flat-farmer-s-day-illustration_33795941.htm#query=farmer%20indian&position=4&from_view=search&track=ais&uuid=99596fe7-c491-401e-abde-745686afff4b">Freepik</a>
        <br/>
        Image by <a href="https://www.freepik.com/free-vector/flat-farmer-s-day-horizontal-banner-template_33795894.htm#query=farmer%20indian&position=3&from_view=search&track=ais&uuid=461cab2b-2dd6-41c6-86b9-4f17885850b9">Freepik</a>
        <br/>
        <a href="https://www.freepik.com/free-photo/plant-gourmet-nature-vegetarian-seedling_1044808.htm#query=sprout&position=7&from_view=search&track=sph&uuid=0e5361a0-dbe6-44f5-88e4-af697b12a4b9">Image by mrsiraphol</a> on Freepik
        <br/>
        <a href="https://www.freepik.com/free-photo/tomatoes_6872443.htm#query=tomato&position=2&from_view=search&track=sph&uuid=2ced4b83-7407-441c-bd18-fa57938f9bf1">Image by Racool_studio</a> on Freepik
        <br/>
        <a href="https://www.freepik.com/free-photo/sugary-delicious-yellow-tropic-isolate_1174504.htm#query=logan%20fruit&position=32&from_view=search&track=ais&uuid=aaf50f55-a42e-4008-a957-cbb191dc7245">Image by 4045</a> on Freepik
        <br/>
        <a href="https://www.freepik.com/free-photo/three-fresh-organic-raw-eggs-isolated-white-surface_13342009.htm#query=egg%20with%20white%20background&position=0&from_view=search&track=ais&uuid=2d593a34-3a15-4a6f-bdd9-8e72de767a8e">Image by azerbaijan_stockers</a> on Freepik
        <br/>
        <a href="https://www.freepik.com/free-photo/green-banana_1134891.htm#query=raw%20banana&position=43&from_view=search&track=ais&uuid=49a1766c-f226-4e76-a9eb-a4de6e1c355e">Image by mrsiraphol</a> on Freepik
        <br/>
        <a href="https://www.freepik.com/free-photo/sweet-caramel_6978093.htm#query=jaggery&position=8&from_view=search&track=sph&uuid=b4a70d17-fb81-4dc4-b1c1-649519036d1c">Image by Racool_studio</a> on Freepik
        <br/>
        <a href="https://www.freepik.com/free-photo/close-up-fresh-apple_6818031.htm#page=3&query=apple%20royal%20gala&position=2&from_view=search&track=ais&uuid=c52373bf-96fe-4a5a-b4fc-d1d8d679d4db">Image by Racool_studio</a> on Freepik
        <br/>
        Image by <a href="https://www.freepik.com/free-psd/view-fresh-apple_38075406.htm#page=5&query=apple%20royal%20gala&position=36&from_view=search&track=ais&uuid=c52373bf-96fe-4a5a-b4fc-d1d8d679d4db">Freepik</a>
        <br/>
        <a href="https://www.freepik.com/free-photo/purple-cabbage-vegetables_1114929.htm#query=cabbage%20red&position=3&from_view=search&track=ais&uuid=e7271ff1-58f1-4006-aef4-318db57eaf81">Image by mrsiraphol</a> on Freepik
        <br/>
        <a href="https://www.freepik.com/free-photo/yellow-pear_3816239.htm#query=golden%20pear&position=12&from_view=search&track=ais&uuid=a0214632-b718-4adc-8f7d-1a50248d89eb">Image by lifeforstock</a> on Freepik
        <br/>
        <a href="https://www.freepik.com/free-photo/closeup-white-chrysanthemum-textured-background_2971983.htm#query=chrysanthemum%20flower&position=2&from_view=search&track=ais&uuid=52a86f92-3608-4d5a-826a-e649d6e0bb3a">Image by rawpixel.com</a> on Freepik
        <br/>
        <a href="https://www.freepik.com/free-photo/colorful-bell-pepper_1114835.htm#query=capsicum%20red&position=10&from_view=search&track=ais&uuid=a083a665-7da2-40d7-8367-6d7b02907ac8">Image by mrsiraphol</a> on Freepik
        <br/>
        <a href="https://www.freepik.com/free-photo/closeup-orange_6048913.htm#page=2&query=mango&position=10&from_view=search&track=sph&uuid=9eeff1e0-112c-4b73-a2be-56415d896d60">Image by Racool_studio</a> on Freepik
        <br/>
        <a href="https://www.freepik.com/free-photo/closeup-fresh-celery-blue-background_2791731.htm#query=celery&position=2&from_view=search&track=sph&uuid=fb241c57-2df0-4521-a7ff-e5669e61302a">Image by rawpixel.com</a> on Freepik
        <br/>
        <a href="https://www.freepik.com/free-photo/spring-onion-isolated-white-background_3778267.htm#query=spring%20onion&position=7&from_view=search&track=ais&uuid=0b217bdd-c1d6-4ed4-9b43-12c3ccab7aaa">Image by lifeforstock</a> on Freepik
        <br/>
        <a href="https://www.freepik.com/free-photo/smooth-skinned-mellow-purple-plum-fruit-isolated-white-background-with-copy-space-text-images-clipping-path-side-view-close-up-shot_27469995.htm#page=3&query=plums&position=17&from_view=search&track=sph&uuid=4eb645a5-0b92-4d1a-ba93-5a15e831b57e">Image by YuliiaKa</a> on Freepik
        <br/>
        <a href="https://www.freepik.com/free-photo/green-beans_6978007.htm#page=7&query=cluster%20beans%20green&position=15&from_view=search&track=ais&uuid=58f429f0-cd40-4aa5-be92-06585cfc4b52">Image by Racool_studio</a> on Freepik
        <br/>
        <a href="https://www.freepik.com/free-photo/green-beans_6978010.htm#page=9&query=cluster%20beans%20green&position=32&from_view=search&track=ais&uuid=58f429f0-cd40-4aa5-be92-06585cfc4b52">Image by Racool_studio</a> on Freepik
        <br/>
        <a href="https://www.freepik.com/free-ai-image/basket-white-potatoes_71900757.htm#query=potato&position=0&from_view=search&track=sph&uuid=f5d73bfe-e9fa-4f4a-a184-50acd150596d">Image By frimufilms</a>
        <br/>
      </div>
      <Footer/>
    </>
  );
}
