import React from 'react';
import {useDispatch} from 'react-redux';
import {useNavigate, useParams} from 'react-router-dom';
import './Filter.css';
import {
  setSearchKey,
} from '../../../features/products/productSlice';
import {
  categories,
  CategoryTranslations,
} from './constants';

/**
 * Component that displays the filter page
 *
 * @return {JSX.Element} Filter component JSX
 */
export default function Filter() {
  const dispatch = useDispatch();
  const {id: selectedCat} = useParams();
  const navigate = useNavigate();

  const handleFilterClick = (category) => {
    dispatch(setSearchKey(''));
    navigate(`/products/${category}`);
  };

  return (
    <>
      <div className="filter_title">Explore our categories</div>
      <div className="filter_container">
        <div className="filter_btnBox">
          {categories.map(({category}) => (
            <button
              key={category}
              onClick={() => handleFilterClick(category)}
              className= {
                selectedCat === category ?
                'filter_btn_selected' :
                'filter_btn'
              }
            >
              {CategoryTranslations[category]}
            </button>
          ))}
        </div>
      </div>
    </>
  );
}
