import React from 'react';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';

/**
 * Component that displays the FavoriteCard page
 *
 * @param {Object} props - The props object containing card information
 * @return {JSX.Element} FavoriteCard component JSX
 */
export default function FavoriteCard(props) {
  const dispatch = () => {};
  const {id, image, title} = props;
  return (
    <div key={id} className="favorite_card">
      <Link to={`/${id}`}>
        <img className="favorite_img" src={image} alt="favorite_image" />
        <div className="favorite_content">
          <div className="favorite_title">
            <span>{title}</span>
          </div>
        </div>
      </Link>
      <button
        onClick={() => dispatch({type: 'ADD_FAVORITE', payload: id})}
        className="favorite_button"
      >
        Delete
      </button>
    </div>
  );
}

FavoriteCard.propTypes = {
  id: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};
