import {
  freeShippingLimit,
  fixedShippingCharge,
} from '../config/constants';

export const getDataForAddressSelect = (locationsList) => {
  const addressList = [];
  locationsList.map((option) => {
    if (option.status === 'active') {
      addressList.push({
        id: option.id,
        label: option.apartmentName,
        value: option.id,
      });
    }
  });
  return addressList;
};


const offerCode = {
  code: 'ABCD',
  disCount: 20,
};

export const sumPrice = (items, isOffer) => {
  const totalPrice = items.reduce((totalPrice, product) => {
    return totalPrice + product.sellPrice * product.count;
  }, 0);

  if (isOffer) {
    const offerPrice = (totalPrice * offerCode.disCount) / 100;
    const totalPriceAfterOffer = totalPrice - offerPrice;

    return {
      totalPrice,
      offerPrice,
      totalPriceAfterOffer,
      ...sumPriceWithSend(totalPrice, offerPrice),
    };
  } else {
    return {
      totalPrice,
      ...sumPriceWithSend(totalPrice),
      shippingCharge: totalPrice <= freeShippingLimit ? fixedShippingCharge : 0,
    };
  }
};

// calc Price With shopping cost
const sumPriceWithSend = (totalPrice, offerPrice = 0) => {
  let totalPriceFinal = null;
  if (totalPrice - offerPrice <= 100_000) {
    totalPriceFinal = totalPrice <= freeShippingLimit ?
      totalPrice + fixedShippingCharge - offerPrice :
      totalPrice - offerPrice;
  } else {
    totalPriceFinal = totalPrice - offerPrice;
  }
  return {totalPriceFinal};
};
