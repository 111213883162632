import React, {useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {useNavigate, useParams} from 'react-router-dom';
import {
  getProducts,
  allProducts,
  productsStatus,
  searchKey,
} from '../../features/products/productSlice';
import './Products.css';
import Filter from './Filter/Filter';
import FilterWithImage from './Filter/FilterWithImage';
import Card from './Card/Card';
import SearchBar from '../SearchBar/SearchBar';
import Footer from '../Footer/Footer';
import Banner from '../Banner/Banner';
import {
  initialLocation,
} from '../../features/locations/locationSlice';
import {uid} from '../../features/user/userSlice';

/**
 * Component that displays the products page
 *
 * @return {JSX.Element} Products component JSX
 */
export default function Products() {
  const navigate = useNavigate();
  const {id: category} = useParams();
  const productsList = useSelector(allProducts);
  const productsLoadStatus= useSelector(productsStatus);
  const searchInput = useSelector(searchKey);
  const selectedLocation = useSelector(initialLocation);
  const userId = useSelector(uid);

  // filter products by selected category.
  let filteredProducts = category === 'ALL' ?
    productsList :
    productsList.filter((product) => product.category === category);

  // search for products in all products
  // this will cancel the category selection.
  filteredProducts = searchInput === '' ?
  filteredProducts :
  productsList.filter(
      (product) =>
        product.title.toLowerCase().includes(searchInput.toLowerCase()),
  );

  const dispatch = useDispatch();
  useEffect(() => {
    if (Object.keys(selectedLocation).length || userId != null) {
      dispatch(getProducts());
    } else {
      navigate('/');
    }
  }, []);

  const showProducts =
    !(category !== 'ALL' ?
    true : searchInput !='' ? true : false);

  return (
    <>
      <div className="search_Container">
        <div className="search_box">
          <SearchBar />
        </div>
      </div>
      <Banner/>
      {showProducts ? <FilterWithImage/> : <Filter />}
      {showProducts ? null :
        <div className="product_container">
          {filteredProducts.length > 0 ? (
            filteredProducts.map(
                (product) => <Card key={product.id} {...product} />,
            )
          ) : (
            <div className="not_products">
              <span className="products_empty_title">
                {productsLoadStatus == 'loading' ? (
                  'Loading'
                ): 'Sorry, no products matched your search!'}
              </span>
              <span className="products_empty_guide">
                {productsLoadStatus == 'loading' ? (
                  ''
                ) : 'Enter another keyword and try'}
              </span>
            </div>
          )}
        </div>
      }
      <Footer tab={'products'}/>
    </>
  );
}
