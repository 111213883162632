import React, {useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {ImSpinner5} from 'react-icons/im';
import {
  currentOrder,
  createOrderStatus,
} from '../../features/order/orderSlice';
import {
  cartSuccess,
  cartId,
} from '../../features/products/productSlice';
import './Confirmation.css';
import {
  Grid,
  Row,
  Col,
} from 'rsuite';
import Footer from '../Footer/Footer';

/**
 * Component that displays the confirmation page
 *
 * @return {JSX.Element} Confirmation component JSX
 */
export default function Confirmation() {
  const order = useSelector(currentOrder);
  const orderStatus = useSelector(createOrderStatus);
  const address = order.address;
  const savedCartId = useSelector(cartId);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(cartSuccess({savedCartId}));
  }, [savedCartId]);

  return (
    <>
      <div className="confirmation-container">
        {orderStatus === 'pending' || orderStatus === 'failed' ? (
          <div>
            <span className="spinner-text">
              {orderStatus === 'failed' ?
                // eslint-disable-next-line max-len
                'There was an error processing your order, Please try again later.' :
                'Please wait while we create your order'
              }
            </span>
            {orderStatus === 'pending' ? (
              <div className="spinner-container">
                <ImSpinner5 className="spinner" />
              </div>) : null
            }
          </div>
        ) : (
          <Grid fluid>
            <Row className="show-grid">
              <Col xs={24} sm={24} md={12} className="confirmation-col">
                <b>Thank you for your order!</b>
                We&apos;re excited to deliver the freshest and
                tastiest fruits and vegetables straight to your door.
                Your order has been confirmed and will be processed shortly.
                We&apos;ll keep you updated on its status every step of the way.
                If you have any questions or concerns,
                please don&apos;t hesitate to reach out to us.
                <p>Thanks again for choosing our fresh produce!</p>
                <br/>
              </Col>
              <Col xs={24} sm={24} md={12} className="confirmation-col">
                <b>Order Details</b>
                <p>Total order value: {order.total_price}</p>
                <p>Shipping charge: {order.shipping_charge}</p>
                <p>Total: {order.total_price_final}</p>
                {address ? (
                  <p>
                    <b>Delivery Address</b>
                    <p className='delivery-address'>
                      <span>{address.name}</span>
                      <span>{address.apartmentName}</span>
                      <span>{address.flatNo}</span>
                      <span>{address.addressLine1}</span>
                      <span>{address.addressLine2}</span>
                      <span>
                        {address.city},
                        {address.state},
                        {address.pinCode}
                      </span>
                    </p>
                  </p>
                  ) : (
                    <p></p>
                  )}
              </Col>
            </Row>
          </Grid>
        )}
      </div>
      <Footer tab={'products'}/>
    </>
  );
}
