import React from 'react';
import PropTypes from 'prop-types';
import Buttons from '../Buttons/Buttons';

/**
 * Component that displays the BasketItem page
 *
 * @param {Object} props - The props object containing product information
 * @return {JSX.Element} BasketItem component JSX
 */
export default function BasketItem(props) {
  const {image, title, sellPrice, optionTitle, count} = props;
  const imageLink =
    `/images/products/${image == '' ? 'default_prod.jpg' : image}`;
  return (
    <div className="basket_item">
      <div className="basket_link">
        <div className="basket_img">
          <img src={imageLink} alt="basket_item" />
        </div>
        <div className="basket_content">
          <span className="basket_title">
            {title}<br></br>
            {optionTitle} <br></br>
            {count} x ₹{sellPrice} = ₹{(sellPrice * count).toLocaleString()}
          </span>
        </div>
      </div>
      <div className="basket_counter">
        <Buttons {...props} />
      </div>
    </div>
  );
}

BasketItem.propTypes = {
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  sellPrice: PropTypes.number.isRequired,
  optionTitle: PropTypes.string.isRequired,
  count: PropTypes.number.isRequired,
  isMultiVariant: PropTypes.bool.isRequired,
};
