import React from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {
  showHalfCart,
  openHalfCart,
} from '../../../features/products/productSlice';
import './CartDrawer.css';
import HalfBasket from '../HalfBasket';
import {
  Drawer,
} from 'rsuite';

/**
 * Component that displays the Drawer page
 *
 * @return {JSX.Element} Drawer component JSX
 */
export default function CartDrawer() {
  const openCart = useSelector(openHalfCart);
  const dispatch = useDispatch();
  return (
    <>
      <Drawer
        size='xs'
        backdrop='static'
        open={openCart}
        placement= 'left'
        onClose={() => dispatch(showHalfCart(false))}>
        <Drawer.Header>
          <Drawer.Title>Cart</Drawer.Title>
        </Drawer.Header>
        <Drawer.Body>
          <HalfBasket/>
        </Drawer.Body>
      </Drawer>
    </>
  );
}
