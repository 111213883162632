import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {fetchAllLocation} from './locationAPI';

const persistedState = JSON.parse(localStorage.getItem('persist:root')) || [];
const persistedLocations = persistedState.locations ?
  JSON.parse(persistedState.locations) :
  {};
const persistedInitialLocation = persistedLocations.initialLocation ?
persistedLocations.initialLocation :
  {};

const initialState = {
  allLocations: [],
  selectedAddress: {},
  status: 'idle',
  newAddressStatus: 'idle',
  initialLocation: persistedInitialLocation,
};

export const getLocations = createAsyncThunk(
    'locations/fetchLocations',
    async () => {
      const response = await fetchAllLocation();
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    },
);

export const locationSlice = createSlice({
  name: 'locations',
  initialState,
  // The `reducers` field lets us define reducers
  // and generate associated actions
  reducers: {
    setSelectedAddress: (state, action) => {
      // state[selectedAddress] = action.payload;
    },
    setInitialAddress: (state, action) => {
      state.initialLocation = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
        .addCase(getLocations.pending, (state) => {
          state.status = 'loading';
        })
        .addCase(getLocations.fulfilled, (state, action) => {
          state.status = 'idle';
          state.allLocations = action.payload;
        });
  },
});

export const {setSelectedAddress, setInitialAddress} = locationSlice.actions;
export const allLocations = (state) => state.locations.allLocations;
export const selectedAddress = (state) => state.locations.selectedAddress;
export const newAddressStatus = (state) => state.locations.newAddressStatus;
export const initialLocation = (state) => state.locations.initialLocation;

export default locationSlice.reducer;
