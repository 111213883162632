import db from '../../firebaseConfig';
import {
  collection,
  getDocs,
  query,
  where,
  addDoc,
  Timestamp,
  // doc,
  updateDoc,
} from 'firebase/firestore';

const fetchOrders = async (uid) => {
  const res = [];
  const q = query(collection(db, 'orders'), where('uid', '==', uid));
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    const data = doc.data();
    res.push({...data});
  });
  return {data: res};
};

/**
 * Fetches all order.
 *
 * @param {string} uid - The string uid to fetch all order.
 * @return {Promise} A promise that resolves with all orders.
 */
export function fetchAllOrders(uid) {
  return new Promise((resolve) =>
    resolve(fetchOrders(uid)),
  );
}

const saveOrder = async (order) => {
  try {
    const now = new Date();
    const docRef = await addDoc(
        collection(db, 'orders'),
        {...order, createdAt: Timestamp.fromDate(now)},
    );
    const id = docRef.id;
    return {
      status: 'success',
      order: {...order, id},
    };
  } catch (error) {
    return {
      status: 'failed',
      order,
    };
  }
};

/**
 * Place order.
 *
 * @param {string} order - The Object order to be saved.
 * @return {Promise} A promise that saves the order.
 */
export function placeOrder(order) {
  return new Promise((resolve) =>
    resolve(saveOrder(order)),
  );
}

const setOrderStatus = async (orderId, status) => {
  try {
    const docRef = query(collection(db, 'orders'), where('id', '==', orderId));
    const snapshot = await getDocs(docRef);

    if (snapshot.empty) {
      console.warn('Order with orderId:', orderId, 'not found');
      return; // Handle document not found scenario (optional)
    }

    const doc = snapshot.docs[0];
    await updateDoc(doc.ref, {status: status});

    return {
      status: 'success',
      order: {orderId, status},
    };
  } catch (error) {
    return {
      status: 'failed',
      order: {orderId},
    };
  }
};

/**
 * cancel order.
 *
 * @param {string} orderId - The Object order to be saved.
 * @return {Promise} A promise that saves the order.
 */
export function cancelOrder(orderId) {
  return new Promise((resolve) =>
    resolve(setOrderStatus(orderId, 'canceled')),
  );
}

const fetchSelectedOrder = async (orderId) => {
  try {
    const res = [];
    const q = query(collection(db, 'orders'), where('uid', '==', orderId));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => res.push(doc.data()));
    return {data: res};
  } catch (error) {
    throw new Error('Login failed. Please try again.');
  }
};

/**
 * Fetches order.
 *
 * @param {string} orderId - The string uid to fetch order.
 * @return {Promise} A promise that resolves with the order.
 */
export function fetchOrder(orderId) {
  return new Promise((resolve) =>
    resolve(fetchSelectedOrder(orderId)),
  );
}
