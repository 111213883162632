import React, {useState} from 'react';
import './HalfBasket.css';
import {useSelector, useDispatch} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import BasketItem from './BasketItem';
import {
  cart,
  showHalfCart,
  clearCart,
} from '../../features/products/productSlice';
import {uid} from '../../features/user/userSlice';
import Login from '../Login/Login';
import {sumPrice} from '../../util/util';

/**
 * Component that displays the HalfBasket page
 *
 * @return {JSX.Element} HalfBasket component JSX
 */
export default function HalfBasket() {
  const basket = useSelector(cart);
  // const user = useAuth();
  const user = useSelector(uid);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showLogin, setShowLogin] = useState(false);

  const handleCheckoutClick = () => {
    if (user) {
      dispatch(showHalfCart(false));
      navigate('/checkout');
    } else {
      setShowLogin(true);
    }
  };

  const handleClearCartClick = () => {
    dispatch(clearCart());
  };

  const prices = sumPrice(basket);
  const totalCartPrice = prices.totalPrice;

  return (
    <>
      {!showLogin ? (
        <div className="half_basket_container">
          {basket.length > 0 ? (
          <>
            <a className='clear_cart' onClick={handleClearCartClick}>
              Clear Cart
            </a>
            <div className="basket_itemBox">
              {basket.map((product) => (
                <BasketItem key={product.optionId} {...product} />
              ))}
            </div>
            <div className="half_basket_priceBox">
              <div className="basket_price">
                <span>Total </span>
                <span>₹ {totalCartPrice.toLocaleString()}</span>
              </div>
              <button
                className="basket_button_buy"
                onClick={handleCheckoutClick}
              >
                Checkout
              </button>
            </div>
          </>
        ) : (
            <div className="favorite_empty">
              <span className="favorite_empty_title">
                The shopping cart is empty
              </span>
            </div>
        )}
        </div>
      ) : (
        <Login/>
      )}
    </>
  );
}
