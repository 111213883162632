export const getDeliverySlot = (addresses, locationsList) => {
  let deliverySlot = null;
  const address = addresses.length > 0 ? addresses[0] : {};
  if (
    locationsList &&
    locationsList.length > 0 &&
    addresses &&
    addresses.length > 0
  ) {
    try {
      const location =
      locationsList.find((item) => item.id === address.locationId);
      const orderCutoffTime = location.orderCutoffTime;
      const deliveryTimings = location.deliveryTimings;
      const currentTime = new Date();
      const targetTime = new Date();
      const cutoffTime = orderCutoffTime.split(':');
      targetTime.setHours(cutoffTime[0], cutoffTime[1], 0);
      const options = {weekday: 'long'};
      const daysOfWeek = [
        'Sunday',
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday',
      ];
      if (currentTime > targetTime) {
        // find next available slot
        currentTime.setDate(currentTime.getDate() + 1);
      }

      const dayName = currentTime.toLocaleDateString('en-US', options);
      let currentIndex = daysOfWeek.indexOf(dayName);
      const finalIndex =
        currentIndex === 0 ? daysOfWeek.length - 1 : currentIndex - 1;

      while (currentIndex !== finalIndex) {
        if (deliveryTimings[daysOfWeek[currentIndex]]) {
          const today = new Date();
          const difference = currentIndex - today.getDay();
          const targetDate = new Date(today);
          targetDate.setDate(today.getDate() + difference);
          const fromTime = deliveryTimings[daysOfWeek[currentIndex]].from;
          const [hours, minutes] = fromTime.split(':');
          targetDate.setHours(parseInt(hours, 10));
          targetDate.setMinutes(parseInt(minutes, 10));

          // eslint-disable-next-line max-len
          deliverySlot = {
            day: daysOfWeek[currentIndex],
            from: fromTime,
            to: deliveryTimings[daysOfWeek[currentIndex]].to,
            deliveryDateTime: targetDate,
            targetTime,
          };
          break;
        }
        currentIndex = currentIndex === 6 ? 0 : currentIndex + 1;
      }
    } catch (err) {
      return deliverySlot;
    }
  }
  return deliverySlot;
};
