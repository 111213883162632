import React, {useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {useParams} from 'react-router-dom';
import {ImSpinner5} from 'react-icons/im';
import './EventRegistration.css';
import {
  saveEvents,
  eventsCreateStatus,
} from '../../features/events/eventsSlice';
import {
  initialLocation,
} from '../../features/locations/locationSlice';
import Footer from '../Footer/Footer';
import {eventsData} from './eventData';

/**
 * Component that displays the login page
 *
 * @return {JSX.Element} Login component JSX
 */
export default function EventRegistration() {
  const [name, setName] = useState('');
  const [flatNo, setFlatNo] = useState('');
  const [mobile, setMobile] = useState('');
  const [showLoader, setShowLoader] = useState(false);
  const selectedLocation = useSelector(initialLocation);
  const eventStatus = useSelector(eventsCreateStatus);
  const params = useParams();
  const eventId = params.id;
  const event = eventsData[eventId];

  const dispatch = useDispatch();

  const handleRegister = () => {
    if (selectedLocation) {
      dispatch(saveEvents({
        uid: selectedLocation.uid ?
          selectedLocation.uid : '',
        eventId,
        name,
        mobile,
        flatNo,
        selectedLocation: {
          apartmentName: selectedLocation.apartmentName,
          locationId: selectedLocation.locationId ?
            selectedLocation.locationId :
            selectedLocation.id ?
            selectedLocation.id :
            '',
          addressLine1: selectedLocation.addressLine1,
          addressLine2: selectedLocation.addressLine2,
          pinCode: selectedLocation.pinCode,
        },
      }));
    } else {
      dispatch(saveEvents({
        eventId,
        name,
        mobile,
        flatNo,
      }));
    }
    setShowLoader(true);
  };

  const isValidName = () => {
    return String(name).toLocaleLowerCase().match(
        /^[a-z][a-z\s]*$/,
    );
  };

  const isValidMobile = () => {
    return String(mobile).toLocaleLowerCase().match(
        /^[0-9]+$/,
    ) && mobile.length === 10;
  };

  const disableRegistration = () => {
    // eslint-disable-next-line max-len
    return !isValidName() || !isValidMobile();
  };

  return (
    <>
      <div className="event-container">
        <h5>{event ? event.heading : ''}</h5>
        <span>{event ? event.content : ''}</span>
        <form>
          <input
            type="text"
            placeholder="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            pattern="[A-Za-z ]+"
            required
          />
          <span className="instruction">
            Please enter the full name of the customer
            (Only alphabets are allowed).
          </span>
          <input
            type="text"
            placeholder="Flat Number"
            value={flatNo}
            onChange={(e) => setFlatNo(e.target.value)}
            pattern="[A-Za-z0-9 ]+"
            required
          />
          <span className="instruction">
            Please enter the flat number at.
          </span>
          <input
            type="text"
            placeholder="Mobile Number"
            value={mobile}
            onChange={(e) => setMobile(e.target.value)}
            pattern="[0-9]{10}"
            required
          />
          <span className="instruction">
            Please enter the mobile number (10 digits only).
          </span>
          <button
            type="button"
            onClick={handleRegister}
            disabled={disableRegistration()}
          >
            {showLoader ?
              eventStatus === 'fulfilled' ?
                'Done, We will contact you with details' :
                <ImSpinner5 className="spinner" /> : 'Register'}
          </button>
        </form>
      </div>
      <Footer/>
    </>
  );
}
