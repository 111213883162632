import React from 'react';
import {Navigate, useRoutes} from 'react-router-dom';
import './App.css';
import Header from './components/Header/Header';
import Products from './components/Products/Products';
import Basket from './components/Basket/Basket';
import Details from './components/Details/Details';
import FavoritePage from './components/Favorite/FavoritePage';
import Login from './components/Login/Login';
import Checkout from './components/Checkout/Checkout';
import Confirmation from './components/Confirmation/Confirmation';
import Orders from './components/Orders/Orders';
import Investments from './components/Investments/Investments';
import SelectLocation from './components/Address/SelectLocation';
import Account from './components/Account/Account';
import EventRegistration from
  './components/EventRegistration/EventRegistration';
import ContentPages from './components/ContentPages/ContentPages';
import PriceAndStockList
  from './components/PriceAndStockList/PriceAndStockList';
import {AuthProvider} from './features/user/AuthContext';

import {Provider} from 'react-redux';
import {store} from './app/store';

/**
 * The main component for the application.
 * @return {JSX.Element} The rendered App component.
 */
function App() {
  const router = useRoutes([
    {path: '/', element: <SelectLocation />},
    {path: '/products', element: <Products />},
    {path: '/products/:id', element: <Products />},
    {path: '/:id', element: <Details />},
    {path: '/favorite', element: <FavoritePage />},
    {path: '/basket', element: <Basket />},
    {path: '/login', element: <Login />},
    {path: '/account', element: <Account />},
    {path: '/checkout', element: <Checkout />},
    {path: '/confirmation', element: <Confirmation />},
    {path: '/orders', element: <Orders />},
    {path: '/investments', element: <Investments/>},
    {path: '/event/:id', element: <EventRegistration/>},
    {path: 'creatives', element: <ContentPages/>},
    {path: '/stock', element: <PriceAndStockList/>},
    {path: '*', element: <Navigate to={'/'} />},
  ]);

  return (
    <React.StrictMode>
      <AuthProvider>
        <Provider store={store}>
          <Header />
          {router}
        </Provider>
      </AuthProvider>
    </React.StrictMode>
  );
}

export default App;
