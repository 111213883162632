import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import './Account.css';
import {
  customerAddresses,
  logout,
} from '../../features/user/userSlice';
import Footer from '../Footer/Footer';


/**
 * Component that displays the Account page
 *
 * @return {JSX.Element} Account component JSX
 */
export default function Account() {
  const dispatch = useDispatch();
  const addresses = useSelector(customerAddresses);
  const handleLogout = () => {
    dispatch(logout());
    setShowLoader(true);
  };

  return (
    <>
      <div className="account-container">
        <h5>Addresses</h5>
        {addresses.map((address) => (
          <div key={address.name}>
            <div>{address.name}</div>
            <div>{address.flatNo}</div>
            <div>{address.apartmentName}</div>
            <div>{address.addressLine1}</div>
            <div>{address.addressLine2}</div>
            <div>{address.city}</div>
            <div>{address.state}</div>
            <div>{address.pinCode}</div>
          </div>
        ))}
      </div>
      <button className='logout-btn' type="button" onClick={handleLogout}>
        Logout
      </button>
      <Footer />
    </>
  );
}
