import React, {useState, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {
  Form,
  SelectPicker,
  Button,
  Grid,
  Row,
  Col,
} from 'rsuite';
import {
  getLocations,
  allLocations,
} from '../../features/locations/locationSlice';
import {
  useAuth,
  userPayload,
  saveCustomerAddress,
} from '../../features/user/userSlice';
import {getDataForAddressSelect} from '../../util/util';
import './NewAddress.css';

/**
 * Component that displays the NewAddress page
 *
 * @return {JSX.Element} NewAddress component JSX
 */
export default function NewAddress() {
  const userDetails = useSelector(userPayload);
  const [name, setName] = useState(userDetails.name);
  const [uid, setUID] = useState('');
  const [phoneNumber, setPhoneNumber] = useState(userDetails.mobile);
  const [apartmentName, setApartmentName] = useState('');
  const [flatNo, setFlatNo] = useState('');
  const [addressLine1, setAddressLine1] = useState('');
  const [addressLine2, setAddressLine2] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [pinCode, setPinCode] = useState('');
  const [locationId, setLocationID] = useState('');
  const [selectedOption, setSelectedOption] = useState('');

  const disabled = () => {
    if (
      name &&
      phoneNumber &&
      apartmentName &&
      flatNo &&
      addressLine1 &&
      addressLine2 &&
      city &&
      state &&
      pinCode
    ) {
      return false;
    }
    return true;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const address = {
      uid,
      name,
      phoneNumber,
      flatNo,
      apartmentName,
      addressLine1,
      addressLine2,
      city,
      state,
      pinCode,
      locationId,
    };
    dispatch(saveCustomerAddress(address));
  };

  const locationsList = useSelector(allLocations);
  const user = useAuth();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getLocations());
  }, []);

  const handleOptionChange = (event) => {
    const optionId = event;
    if (optionId) {
      const selectedOption =
        locationsList.find((option) => option.id === optionId);
      setSelectedOption(selectedOption);
      setApartmentName(selectedOption.apartmentName);
      setAddressLine1(selectedOption.addressLine1);
      setAddressLine2(selectedOption.addressLine2);
      setCity(selectedOption.city);
      setState(selectedOption.state);
      setPinCode(selectedOption.pinCode);
      setUID(user.uid);
      setLocationID(optionId);
    }
  };

  return (
    <>
      <Form className='new-address-form'>
        <Form.Group controlId={'input-apartment'}>
          <SelectPicker
            value={selectedOption.id}
            placeholder="Select Location"
            data={getDataForAddressSelect(locationsList)}
            cleanable={false}
            onChange={handleOptionChange}
            className='select-apartment'
          />
        </Form.Group>
        <Grid fluid>
          <Row className="show-grid">
            <Col xs={24} sm={24} md={8}>
              <Form.Group controlId="name">
                <Form.ControlLabel>Flat no / House no:</Form.ControlLabel>
                <Form.Control
                  id="flatNo"
                  name="flatNo"
                  value={flatNo}
                  onChange={(event) => setFlatNo(event)}
                  className="address-input"
                />
                <Form.HelpText>Flat no / House no is required</Form.HelpText>
              </Form.Group>
            </Col>
            <Col xs={24} sm={24} md={8}>
              <Form.Group controlId="name">
                <Form.ControlLabel>Name</Form.ControlLabel>
                <Form.Control
                  id="name"
                  name="name"
                  value={name}
                  onChange={(event) => setName(event)}
                  className="address-input"
                />
                <Form.HelpText>Name is required</Form.HelpText>
              </Form.Group>
            </Col>
            <Col xs={24} sm={24} md={8}>
              <Form.Group controlId="phoneNumber">
                <Form.ControlLabel>Phone Number</Form.ControlLabel>
                <Form.Control
                  id="phoneNumber"
                  name="phoneNumber"
                  value={phoneNumber}
                  onChange={(event) => setPhoneNumber(event)}
                  className="address-input"
                />
                <Form.HelpText>Phone number is required</Form.HelpText>
              </Form.Group>
            </Col>
          </Row>
        </Grid>
        <Grid fluid>
          <Row className="show-grid">
            <Col xs={24} sm={24} md={8}>
              <Form.Group controlId="addressLine1">
                <Form.ControlLabel>Address Line 1</Form.ControlLabel>
                <Form.Control
                  id="addressLine1"
                  name="addressLine1"
                  value={addressLine1}
                  onChange={(event) => setAddressLine1(event)}
                  className="address-input"
                  disabled
                />
                <Form.HelpText>Address Line 1</Form.HelpText>
              </Form.Group>
            </Col>
            <Col xs={24} sm={24} md={8}>
              <Form.Group controlId="addressLine2">
                <Form.ControlLabel>Address Line 2</Form.ControlLabel>
                <Form.Control
                  id="addressLine2"
                  name="addressLine2"
                  value={addressLine2}
                  onChange={(event) => setAddressLine2(event)}
                  className="address-input"
                  disabled
                />
                <Form.HelpText>Address Line 2</Form.HelpText>
              </Form.Group>
            </Col>
            <Col xs={24} sm={24} md={8}>
              <Form.Group controlId="city">
                <Form.ControlLabel>City</Form.ControlLabel>
                <Form.Control
                  id="city"
                  name="city"
                  value={city}
                  onChange={(event) => setCity(event)}
                  className="address-input"
                  disabled
                />
                <Form.HelpText>City</Form.HelpText>
              </Form.Group>
            </Col>
          </Row>
        </Grid>
        <Grid fluid>
          <Row className="show-grid">
            <Col xs={24} sm={24} md={8}>
              <Form.Group controlId="state">
                <Form.ControlLabel>State</Form.ControlLabel>
                <Form.Control
                  id="state"
                  name="state"
                  value={state}
                  onChange={(event) => setState(event)}
                  className="address-input"
                  disabled
                />
                <Form.HelpText>State</Form.HelpText>
              </Form.Group>
            </Col>
            <Col xs={24} sm={24} md={8}>
              <Form.Group controlId="Pin Code">
                <Form.ControlLabel>Pin Code</Form.ControlLabel>
                <Form.Control
                  id="pinCode"
                  name="pinCode"
                  value={pinCode}
                  onChange={(event) => setPinCode(event)}
                  className="address-input"
                  disabled
                />
                <Form.HelpText>Pin Code</Form.HelpText>
              </Form.Group>
            </Col>
          </Row>
        </Grid>
        <Button
          className='btn-save'
          onClick={handleSubmit}
          disabled={disabled()}
        >
          { disabled() ? 'Please fill all fields' : 'Save' }
        </Button>
      </Form>
    </>
  );
}
