import React from 'react';
import {useSelector} from 'react-redux';
import {sumPrice} from '../../util/util';
import {freeShippingLimit} from '../../config/constants';
import {
  cart,
} from '../../features/products/productSlice';

/**
 * Component that displays the SendProducts page
 *
 * @return {JSX.Element} SendProducts component JSX
 */
export default function SendProducts() {
  const basket = useSelector(cart);
  const prices = sumPrice(basket);
  const cartOfferPrice = prices.offerPrice;
  const cartTotalPrice = prices.totalPrice;
  const cartShippingCharge = prices.shippingCharge;

  return (
    <div className="send_products">
      <div className="send_info_price">
        <span>Shipping cost</span>
        <span>
          {cartTotalPrice - cartOfferPrice <= freeShippingLimit ?
            `Rs ${cartShippingCharge.toLocaleString()}` :
            'Free'}
        </span>
      </div>
      {cartTotalPrice - cartOfferPrice > freeShippingLimit && (
        <div className="send_free_img">
          <img src="/images/free.svg" alt="free" />
        </div>
      )}
    </div>
  );
}
