import {useContext} from 'react';
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {
  registerUser,
  loginUser,
  logoutUser,
  saveAddress,
  resetUserPassword,
} from './userAPI';
import {AuthContext} from './AuthContext';

const persistedState = JSON.parse(localStorage.getItem('persist:root')) || [];
const persistedUser = persistedState.user ?
  JSON.parse(persistedState.user) :
  {};
const persistedUid = persistedUser.uid ? persistedUser.uid : null;
const persistedRole = persistedUser.role ? persistedUser.role : null;
const persistedInvestments =
  persistedUser.investments ? persistedUser.investments : {};
const persistedCustomerAddresses =
  persistedUser.customerAddresses ? persistedUser.customerAddresses : [];
const persistedSelectedAddress =
  persistedUser.selectedAddress ? persistedUser.selectedAddress : {};

const initialState = {
  status: 'idle',
  userPayload: {},
  uid: persistedUid,
  registrationError: '',
  loginError: '',
  passwordResetError: '',
  role: persistedRole,
  investments: persistedInvestments,
  customerAddresses: persistedCustomerAddresses,
  fetchAddressStatus: 'idle',
  saveAddressStatus: 'idle',
  selectedAddress: persistedSelectedAddress,
};

export const login = createAsyncThunk(
    'user/login',
    async ({email, password}) => {
      try {
        return await loginUser(email, password);
      } catch (error) {
        throw new Error('Login failed. Please try again.');
      }
    },
);

export const registration = createAsyncThunk(
    'user/register',
    async ({email, password, name, mobile, flatNo, selectedLocation}) => {
      return await registerUser(
          email,
          password,
          name,
          mobile,
          flatNo,
          selectedLocation,
      );
    },
);

export const logout = createAsyncThunk(
    'user/logout',
    async () => {
      return await logoutUser();
    },
);

export const saveCustomerAddress = createAsyncThunk(
    'locations/saveCustomerAddress',
    async (address) => {
      return await saveAddress(address);
    },
);

export const resetPassword = createAsyncThunk(
    'user/resetPassword',
    async (email) => {
      return await resetUserPassword(email);
    },
);

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    refreshUserId: (state, action) => {
      state.uid = action.payload.uid;
    },
    clearError: (state) => {
      state.registrationError = '';
      state.loginError = '';
      state.passwordResetError = '';
    },
  },
  extraReducers: (builder) => {
    builder
        .addCase(login.pending, (state) => {
          state.status = 'loading';
        })
        .addCase(login.fulfilled, (state, action) => {
          state.status = 'idle';
          state.fetchAddressStatus = 'idle';
          state.saveAddressStatus = 'idle';
          if (action.payload.uid) {
            state.uid = action.payload.uid;
            state.role = action.payload.role;
            if (action.payload.role === 'investor') {
              state.investments = action.payload.investments;
            }
            state.userPayload = action.payload;
            state.customerAddresses =
              action.payload.address ? action.payload.address : [];
            state.selectedAddress =
              action.payload.address && action.payload.address.length > 0 ?
              action.payload.address[0] : {};
            state.loginError = '';
          } else {
            state.loginError = action.payload;
          }
        })
        .addCase(login.rejected, (state, action) => {
          state.loginError = 'LOGIN_ERROR';
        })
        .addCase(registration.pending, (state) => {
          state.status = 'loading';
        })
        .addCase(registration.fulfilled, (state, action) => {
          state.status = 'idle';
          state.fetchAddressStatus = 'idle';
          state.saveAddressStatus = 'idle';
          if (action.payload.uid) {
            state.uid = action.payload.uid;
            state.userPayload = action.payload;
            state.registrationError = '';
          } else {
            state.registrationError = action.payload;
          }
        })
        .addCase(logout.pending, (state) => {
          state.status = 'loading';
        })
        .addCase(logout.fulfilled, (state) => {
          state.status = 'idle';
          state.fetchAddressStatus = 'idle';
          state.saveAddressStatus = 'idle';
          state.uid = null;
          state.role = null;
          state.userPayload = {};
          state.investments = {};
          state.customerAddresses = [];
          state.selectedAddress = {};
        })
        .addCase(saveCustomerAddress.pending, (state) => {
          state.newAddressStatus = 'pending';
          state.customerAddresses = [];
          state.selectedAddress = {};
        })
        .addCase(saveCustomerAddress.fulfilled, (state, action) => {
          state.saveAddressStatus = 'fulfilled';
          state.customerAddresses = action.payload.address;
          state.selectedAddress = action.payload.address[0];
        })
        .addCase(resetPassword.rejected, (state, action) => {
          state.passwordResetError = action.payload;
        })
        .addCase(resetPassword.fulfilled, (state, action) => {
          state.passwordResetError = action.payload;
        });
  },
});

export const uid = (state) => state.user.uid;
export const userPayload = (state) => state.user.userPayload;
export const role = (state) => state.user.role;
export const investments = (state) => state.user.investments;
export const customerAddresses = (state) => state.user.customerAddresses;
export const registrationError = (state) => state.user.registrationError;
export const loginError = (state) => state.user.loginError;
export const fetchAddressStatus = (state) => state.user.fetchAddressStatus;
export const saveAddressStatus = (state) => state.user.saveAddressStatus;
export const selectedAddress = (state) => state.user.selectedAddress;
export const passwordResetError = (state) => state.user.passwordResetError;
export const useAuth = () => {
  const {currentUser} = useContext(AuthContext);
  return currentUser;
};
export const {refreshUserId, clearError} = userSlice.actions;
export default userSlice.reducer;
