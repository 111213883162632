import React, {useEffect, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import './Address.css';
import {
  allLocations,
  getLocations,
  initialLocation,
  setInitialAddress,
} from '../../features/locations/locationSlice';
import {uid} from '../../features/user/userSlice';
import {
  Modal,
  Button,
  Loader,
  SelectPicker,
} from 'rsuite';
import {getDataForAddressSelect} from '../../util/util';
import * as turf from '@turf/turf';
import Footer from '../Footer/Footer';

/**
 * Component that displays the Address page
 *
 * @return {JSX.Element} Address component JSX
 */
function SelectLocation() {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [locationId, setLocationId] = useState(null);
  const [locationName, setLocationName] = useState('');
  const [showLoader, setShowLoader] = useState(false);
  // const [inside, setInside] = useState(false);

  const dispatch = useDispatch();
  const locations = useSelector(allLocations);
  const selectedLocation = useSelector(initialLocation);
  const userId = useSelector(uid);

  useEffect(() => {
    if (Object.keys(selectedLocation).length || userId != null) {
      navigate('/products/ALL');
    } else {
      dispatch(getLocations());
      setOpen(true);
    }
  }, [selectedLocation, userId, navigate]);

  const handleOptionChange = (event) => {
    const optionId = event;
    setLocationId(optionId);
  };

  const handleContinue = () => {
    const selectedOption =
      locations.find((option) => option.id === locationId);
    dispatch(setInitialAddress(selectedOption));
    setShowLoader(true);
  };

  if ('geolocation' in navigator) {
    navigator.geolocation.getCurrentPosition((position) => {
      const userLocation = {
        latitude: position.coords.latitude,
        longitude: position.coords.longitude,
      };
      // You can now use userLocation to check for delivery availability.
      const userPoint =
        turf.point([userLocation.latitude, userLocation.longitude]);

      for (const location of locations) {
        if (location.geofences.coordinates) {
          const coordinates = [];
          for (const coordinate of location.geofences.coordinates) {
            coordinates.push([coordinate._lat, coordinate._long]);
          }
          const areaPolygon = turf.polygon([coordinates]);
          if (turf.booleanPointInPolygon(userPoint, areaPolygon)) {
            setLocationId(location.id);
            setLocationName(location.apartmentName);
            break;
          } else {
          }
        }
      }
    });
  } else {
    // Geolocation is not supported in this browser.
    // Handle accordingly.
  }

  return (
    <>
      <Modal
        backdrop="static"
        open={open}
      >
        <Modal.Body>
          <h4>Welcome to Green Yogi!</h4>
          <span className='select-location-content'>
            We&apos;re excited to have you shop with us for
             fresh and delicious fruits and vegetables.
            To provide you with the best service,
             please select your delivery location from
              the options below:
          </span>
          {locations.length <= 0 ?
            <Loader size="md" /> :
            <SelectPicker
              placeholder={locationId ? locationName : 'Select Location'}
              data={getDataForAddressSelect(locations)}
              cleanable={false}
              className='select-apartment'
              onChange={handleOptionChange}
            />
          }
          <span className='select-location-content'>
            Don&apos;t see your location listed? No worries! We&apos;re
             constantly expanding our reach. Feel free to contact our customer
              support for more information.
          </span>
          <span className='select-location-content'>
            Choose your location and start exploring our wide variety
             of farm-fresh products. Thank you for choosing Green Yogi!
          </span>
          <span className='select-location-content'>Happy shopping!</span>
        </Modal.Body>
        <Modal.Footer>
          {locationId != null ?
            <Button onClick={handleContinue} appearance="primary">
              {showLoader ? <Loader/> : 'Continue'}
            </Button> :
            null
          }
        </Modal.Footer>
      </Modal>
      <Footer/>
    </>
  );
}

export default SelectLocation;
