import React from 'react';
import './FavoritePage.css';
import {Link} from 'react-router-dom';
import {HiArrowRight} from 'react-icons/hi';
import FavoriteCard from './FavoriteCard';

/**
 * Component that displays the Favorite Page page
 *
 * @return {JSX.Element} Favorite Page component JSX
 */
export default function FavoritePage() {
  const state = {
    basket: [],
    favorites: [],
    offerPrice: 100,
    totalPriceFinal: 100,
  };
  const dispatch = () => {};

  return (
    <>
      <div className="favorite_container_linkBar">
        <div className="favorite_linkBar">
          <span>Interests</span>
          <Link className="favorite_backLink" to={'/'}>
            <HiArrowRight />
            Products page
          </Link>
        </div>
      </div>
      <div className="favorite-wrapper">
        {state.favorites.length > 0 ? (
          <>
            <div>
              <button
                onClick={() => dispatch({type: 'REMOVE_ALL_FAVORITE'})}
                className="favorite_removeAll"
              >
                remove all
              </button>
            </div>
            <div className="favorite_container">
              {state.favorites.map((product) => (
                <FavoriteCard key={product.id} {...product} />
              ))}
            </div>
          </>
        ) : (
          <div className="favorite_empty">
            <img
              className="favorite_empty_img"
              src="/images/empty_favorite.svg"
              alt=""
            />
            <span className="favorite_empty_title">Interests are empty</span>
          </div>
        )}
      </div>
    </>
  );
}
